import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config";
import SaleHeader from "./PrintHeaderAccount";
import dayjs from "dayjs";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { Spin } from "antd";
import moment from "moment";

function PrintSaleReport() {
  const [loading, setLoading] = useState(false);

  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  const urlParams = new URLSearchParams(window.location.search);
  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");
  const ProductID = urlParams.get("ProductID");
  const AccountID = urlParams.get("AccountID");
  const [data, setData] = useState([]);

  const [salesList, setSalesList] = useState([]);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageNo: 1,
      PageSize: 2000,
      StartDate: StartDate,
      EndDate: EndDate,
      ProductID: ProductID,
      CustomerID: 0,
      AccountID: AccountID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetSalesReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    console.log(data);

    axios(api_config)
      .then(function (response) {
        console.log("sales data", response.data);
        if (response.data.status_code == 1) {
          setSalesList(response.data.listofSales);
          setData(response.data);

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
      //flexGrow: 1
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    table: {
      display: "table",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    th: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },

    footerTh: {
      padding: 5,
      borderStyle: "solid",
      borderBottom: 0.3,
      borderColor: "#000",
    },
    thValue: {
      fontSize: "8pt",
      fontWeight: "bold",
    },
    thFooterSubValue: {
      fontSize: "9pt",
    },
    footerValue: {
      fontSize: "10pt",
      fontWeight: "bold",
    },

    row: {
      flexDirection: "row",
    },
    td: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    tdValue: {
      fontSize: "8pt",
      color: "black",
    },
  });

  const generateData = () => {
    if (salesList.length > 0) {
      return salesList.map((item, index) => {
        return (
          <View key={index} style={styles.row}>
            <View style={[styles.td, { width: 40 }]}>
              <Text style={styles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[styles.td, { width: 60 }]}>
              <Text style={styles.tdValue}>
                {moment(item.Created_Date).format(config.date_format)}
              </Text>
            </View>
            <View style={[styles.td, { width: 90 }]}>
              <Text style={styles.tdValue}>{item.Account_BE?.Name}</Text>
            </View>
            <View style={[styles.td, { width: 70 }]}>
              <Text style={styles.tdValue}>{item.Product_BE?.Name}</Text>
            </View>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{item.Receipt_No}</Text>
            </View>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{item.Vehicle_No}</Text>
            </View>
            <View style={[styles.td, { width: 50, textAlign: "right" }]}>
              <Text style={styles.tdValue}>{item.Price}</Text>
            </View>
            <View style={[styles.td, { width: 50, textAlign: "right" }]}>
              <Text style={styles.tdValue}>{item.Quantity}</Text>
            </View>
            <View style={[styles.td, { width: 100, textAlign: "right" }]}>
              <Text style={styles.tdValue}>{item.Amount}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <SaleHeader />
        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={{ fontSize: "12pt" }}>Sales Report</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 40 }]}>
                <Text style={styles.thValue}>SR.</Text>
              </View>
              <View style={[styles.th, { width: 60 }]}>
                <Text style={styles.thValue}>Dated</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Customer</Text>
              </View>
              <View style={[styles.th, { width: 70 }]}>
                <Text style={styles.thValue}>Product</Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>Receipt</Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>Vehicle</Text>
              </View>
              <View style={[styles.th, { width: 50, textAlign: "right" }]}>
                <Text style={styles.thValue}>Price</Text>
              </View>
              <View style={[styles.th, { width: 50, textAlign: "right" }]}>
                <Text style={styles.thValue}>Quantity</Text>
              </View>
              <View style={[styles.th, { width: 100, textAlign: "right" }]}>
                <Text style={styles.thValue}>Amount</Text>
              </View>
            </View>

            {generateData()}

            <View
              style={[
                styles.tableHead,
                { width: 555, display: "flex", justifyContent: "flex-end" },
              ]}
            >
              <View style={styles.footerTh}>
                <Text style={[styles.footerValue, { textAlign: "right" }]}>
                  Total Sales: {data.TotalSales}
                </Text>
              </View>
            </View>
            <View
              style={[
                styles.tableHead,
                { width: 555, display: "flex", justifyContent: "flex-end" },
              ]}
            >
              <View style={styles.footerTh}>
                <Text style={styles.footerValue}>
                  Cash Sales: {data.TotalSales}
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableHead,
                { width: 555, display: "flex", justifyContent: "flex-end" },
              ]}
            >
              <View style={styles.footerTh}>
                <Text style={styles.footerValue}>
                  Credit Sales: {data.TotalCreditSales}
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableHead,
                { width: 555, display: "flex", justifyContent: "flex-end" },
              ]}
            >
              <View style={styles.footerTh}>
                <Text style={styles.footerValue}>
                  Total Quantity of Diesel: {data.TotalDieselQty}
                </Text>
              </View>
            </View>

            <View
              style={[
                styles.tableHead,
                { width: 555, display: "flex", justifyContent: "flex-end" },
              ]}
            >
              <View style={styles.footerTh}>
                <Text style={styles.footerValue}>
                  Total Quantity of Petrol: {data.TotalPetrol}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintSaleReport;
