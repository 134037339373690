import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { Logos } from "../../Shared/Logos";

// import moment from 'moment';

import dayjs from "dayjs";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
// import axios from 'axios';
// import config from '../../config';
// import { MailOutlined, PhoneOutlined, PrinterOutlined } from '@ant-design/icons';

function PrintHeaderAccount() {
  const [listofAccounts, SetlistofAccounts] = useState([]);
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");

  const LogoPumpName = localStorage.getItem("PumpName");
  const Email_Address = localStorage.getItem("Email_Address");
  const Mobile_No = localStorage.getItem("Mobile_No");
  const PumpName = localStorage.getItem("PumpName");
  const Address = localStorage.getItem("Address");
  const PumpLogo = localStorage.getItem("pumpLogo");

  console.log(PumpLogo);

  const styles = StyleSheet.create({
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
  });

  const matchingLogo = Logos.find((logo) => logo.title === PumpLogo);

  return (
    <>
      <View style={styles.header} fixed>
      {matchingLogo ? (
      <Image source={matchingLogo.src} style={{ width: 50, height: 50 }} />
    ) : (
      <Text>Logo</Text>
    )}
        <View style={{ marginRight: "130px",marginLeft:"20px" }}>
          <Text>{LogoPumpName}</Text>
          <Text style={{ fontSize: "10pt", marginTop: "5px" }}>{Address}</Text>
        </View>
        <View style={{marginRight:"10px"}}>
          <Text style={{ fontSize: "10pt", marginBottom: 5 }}>
            {Email_Address}
          </Text>
          <Text style={{ fontSize: "10pt", marginBottom: 5 }}>{Mobile_No}</Text>
          <Text style={{ fontSize: "8pt" }}>
            Print On {dayjs().format("DD MMM YYYY hh:mm a")}
          </Text>
        </View>
      </View>
    </>
  );
}

export default PrintHeaderAccount;
