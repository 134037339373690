import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import PrintHeaderAccount from "../PrintHeaderAccount";
import { calculateColumnTotal } from "../../Shared/Utility";
import { Spin } from "antd";

function PrintAccountLedger() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [AccountName,setAccountName] = useState("");
  const [listofLedger, SetlistofLedger] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const account_id = urlParams.get("account_id");
  const PrintType = urlParams.get("PrintType");
  const TitleType = urlParams.get("TitleType");
  const StartDate = urlParams.get("StartDate")
  const EndDate = urlParams.get("EndDate")
  const account_name = urlParams.get("Name");
  const today = new Date();
  const date = moment().format("DD MMM YYYY");

  const [ListOfLedgerOld, setListOfLedgerOld] = useState([]);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageSize: 100000,
      StartDate: StartDate,
      EndDate: EndDate,
      PageNo: 1,
      ID: account_id,
    };

   
    

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          setListOfLedgerOld(response.data.listofLedger);
          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: account_id,
    };

    console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setLoading(false);
          setAccountName(response.data.Name);
        } else {
          // setLoadingGetAccount(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (PrintType == "Sale") {
      const filterd = ListOfLedgerOld.filter(
        (item) => item.Reference_Type == "Sale"
      );
      SetlistofLedger(filterd);
    } else {
      SetlistofLedger(ListOfLedgerOld);
    }
  }, [ListOfLedgerOld]);

  // Create styles
  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
      //flexGrow: 1
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    table: {
      display: "flex",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    th: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    footerth: {
      padding: 5,
    },
    thValue: {
      fontSize: "8pt",
      fontWeight: "bold",
    },

    row: {
      flexDirection: "row",
    },
    td: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    tdValue: {
      fontSize: "8pt",
    },
  });
  const generateData = () => {
    if (listofLedger.length > 0) {
      if (PrintType == "All") {
        return listofLedger.map((item, index) => {
          return (
            <View key={index} style={styles.row}>
              <View style={[styles.td, { width: 20 }]}>
                <Text style={styles.tdValue}>{index + 1}</Text>
              </View>
              <View style={[styles.td, { width: 60 }]}>
                <Text style={styles.tdValue}>
                  {moment(item.Transaction_Date).format(config.date_format)}
                </Text>
              </View>
              <View style={[styles.td, { width: 200 }]}>
                <Text style={styles.tdValue}>{item.Description}</Text>
              </View>
              <View style={[styles.td, { width: 50 }]}>
                <Text style={styles.tdValue}>{item.Vehicle_No}</Text>
              </View>
              <View style={[styles.td, { width: 50 }]}>
                <Text style={styles.tdValue}>{item.Receipt_No}</Text>
              </View>
              <View style={[styles.td, { textAlign: "right", width: 50 }]}>
                <Text style={styles.tdValue}>{item.Debit}</Text>
              </View>
              <View style={[styles.td, { textAlign: "right", width: 45 }]}>
                <Text style={styles.tdValue}>{item.Credit}</Text>
              </View>
              <View style={[styles.td, { textAlign: "right", width: 55 }]}>
                <Text style={styles.tdValue}>{item.Balance}</Text>
              </View>
              <View style={[styles.td, { width: 30 }]}>
                <Text style={styles.tdValue}>{item.BalanceType}</Text>
              </View>
            </View>
          );
        });
      } else if (PrintType == "Sale") {
        return listofLedger.map((item, index) => {
          if (item.Reference_Type == "Sale") {
            return (
              <View key={index} style={styles.row}>
                <View style={[styles.td, { width: 20 }]}>
                  <Text style={styles.tdValue}>{index + 1}</Text>
                </View>
                <View style={[styles.td, { width: 60 }]}>
                  <Text style={styles.tdValue}>
                    {moment(item.Transaction_Date).format(config.date_format)}
                  </Text>
                </View>
                <View style={[styles.td, { width: 200 }]}>
                  <Text style={styles.tdValue}>{item.Description}</Text>
                </View>
                <View style={[styles.td, { width: 50 }]}>
                  <Text style={styles.tdValue}>{item.Vehicle_No}</Text>
                </View>
                <View style={[styles.td, { width: 50 }]}>
                  <Text style={styles.tdValue}>{item.Receipt_No}</Text>
                </View>
                <View style={[styles.td, { textAlign: "right", width: 50 }]}>
                  <Text style={styles.tdValue}>{item.Debit}</Text>
                </View>
                <View style={[styles.td, { textAlign: "right", width: 45 }]}>
                  <Text style={styles.tdValue}>{item.Credit}</Text>
                </View>
                <View style={[styles.td, { textAlign: "right", width: 55 }]}>
                  <Text style={styles.tdValue}>{item.Balance}</Text>
                </View>
                <View style={[styles.td, { width: 30 }]}>
                  <Text style={styles.tdValue}>{item.BalanceType}</Text>
                </View>
              </View>
            );
          }
        });
      }
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <PrintHeaderAccount />
        <View style={styles.section}>
          <View style={styles.title}>
            {TitleType == "Sales" ? (
              <Text style={{ fontSize: "12pt" }}>
                Sales Invoice for {account_name}{" "}
              </Text>
            ) : (
              <Text style={{ fontSize: "12pt" }}>
                Ledger details of {AccountName}{" "}
              </Text>
            )}

            <Text style={{ fontSize: "10pt" }}>{date}</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 20 }]}>
                <Text style={styles.thValue}>SR.</Text>
              </View>
              <View style={[styles.th, { width: 60 }]}>
                <Text style={styles.thValue}>Dated.</Text>
              </View>
              <View style={[styles.th, { width: 200 }]}>
                <Text style={styles.thValue}>Description</Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>Vehicle No</Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>Receipt No</Text>
              </View>
              <View style={[styles.th, { textAlign: "right", width: 50 }]}>
                <Text style={styles.thValue}>Debit</Text>
              </View>
              <View style={[styles.th, { textAlign: "right", width: 45 }]}>
                <Text style={styles.thValue}>Credit</Text>
              </View>
              <View style={[styles.th, { textAlign: "right", width: 55 }]}>
                <Text style={styles.thValue}>Balance</Text>
              </View>
              <View style={[styles.th, { width: 30 }]}>
                <Text style={styles.thValue}>Type</Text>
              </View>
            </View>
            {generateData()}
            <View style={styles.tableHead}>
              <View
                style={[styles.footerth, { width: 375, textAlign: "right" }]}
              >
                <Text style={styles.thValue}>Total:</Text>
              </View>
              <View
                style={[styles.footerth, { width: 50, textAlign: "right" }]}
              >
                <Text style={styles.thValue}>
                  {Math.round(calculateColumnTotal(listofLedger, "Debit"))}
                </Text>
              </View>
              <View
                style={[styles.footerth, { width: 50, textAlign: "right" }]}
              >
                <Text style={styles.thValue}>
                  {calculateColumnTotal(listofLedger, "Credit")}
                </Text>
              </View>
              <View
                style={[styles.footerth, { width: 50, textAlign: "right" }]}
              >
                <Text style={styles.thValue}>
                  {Math.round(calculateColumnTotal(listofLedger, "Balance"))}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintAccountLedger;
