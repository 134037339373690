import React, { useState } from "react";
import axios from "axios";
import config from "../../config";
import {
  Space,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  DatePicker,
  Form,
  Select,
  Spin,
} from "antd";
import { NavLink } from "react-router-dom";
import LoginChecked from "../Shared/LoginChecked";

function PaymentReceipts() {
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Sr. #",
      dataIndex: "Sr",
      key: "Sr",
    },
    {
      title: "Account Name",
      dataIndex: "Account Name",
      key: "Name",
      //   ...getColumnSearchProps('Name'),
    },
    {
      title: "	Current Balance	",
      dataIndex: "	CurrentBalance	",
      key: "	CurrentBalance	",
    },
    {
      title: "Type",
      dataIndex: "BalanceType",
      key: "type",
      filters: [
        {
          text: "Credit",
          value: "Credit",
        },
        {
          text: "Debit",
          value: "Debit",
        },
      ],
      onFilter: (value, record) => record.BalanceType === value,
    },
    {
      title: "Amount Received",
      dataIndex: "Amount Received	",
      key: "Amount Received	",
    },
    {
      title: "Description",
      dataIndex: "Description	",
      key: "Description",
    },
    {
      title: "Account",
      dataIndex: "Account",
      key: "Account",
    },
  ];

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Payment Receipts</h3>
          </div>

          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <DatePicker />
              </Form.Item>
              {/* <Form.Item>
              <NavLink to="#" target="_blank">
                <Button
                  type="default"
                  htmlType="submit"
                  loading={loading}
                >
                  {loading ? <Spin /> : "View"}
                </Button>
              </NavLink>
              </Form.Item> */}
            </Form>
          </div>

          <Table
            size="small"
            // loading={loading}
            columns={columns}
            // dataSource={visibleData}
            // onChange={handleTableChange}
            pagination={false}
          />
        </div>
      </div>
    </>
  );
}

export default PaymentReceipts;
