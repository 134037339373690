import React, { useState, useEffect } from "react";
import Header from "./PrintHeaderAccount";
import axios from "axios";
import config from "../../../config";
import moment from "moment";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { Spin, Space } from "antd";

function PrintProfitOnSale() {
  const [laodng, setLoading] = useState(false);
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const [ShowDetails, SetShowDetails] = useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const EndDate = urlParams.get("EndDate");
  const StartDate = urlParams.get("StartDate");
  const ProductID = urlParams.get("ProductID");
  const SupplierID = urlParams.get("SupplierID");
  // const ShowDetails = urlParams.get("ShowDetails");
  const [data, setData] = useState([]);

  console.log(ShowDetails)

  const [listProfitOnSale, setListProfitOnSale] = useState([]);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      ProductID: ProductID,
      SupplierID: SupplierID,
      ShowDetails: ShowDetails,
      PageNo: 1,
      PageSize: 2000,
    };

    console.log("this is data", data);
    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetProfitOnSalesReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setListProfitOnSale(response.data.ListofProducts);
          setData(response.data);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
      //flexGrow: 1
    },
    thFooter: {
      flex: 1,
      padding: 5,
      fontSize: 10,
    },
    valueFooter: {
      fontSize: "8pt",
      fontWeight: "bold",
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    table: {
      display: "flex",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    th: {
      //flex: 1,
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      flexShrink: 0,
    },
    thValue: {
      fontSize: "8pt",
      fontWeight: "bold",
    },

    row: {
      flexDirection: "row",
    },
    td: {
      //flex: 1,
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      flexShrink: 0,
    },
    tdValue: {
      fontSize: "8pt",
      color: "black",
    },
  });

  const generateData = () => {
    if (listProfitOnSale.length > 0) {
      return listProfitOnSale.map((item, index) => {
        return (
          <View key={index} style={styles.row}>
            <View style={[styles.td, { width: "40" }]}>
              <Text style={styles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[styles.td, { width: "60" }]}>
              <Text style={styles.tdValue}>
                {moment(item.Created_Date).format(config.date_format)}
              </Text>
            </View>
            <View style={[styles.td, { width: "80" }]}>
              <Text style={styles.tdValue}>{item.Name}</Text>
            </View>
            <View style={[styles.td, { width: "80" }]}>
              <Text style={styles.tdValue}>{Number(item.Balance).toFixed(2)}</Text>
            </View>
            <View style={[styles.td, { width: "80", textAlign: "right" }]}>
              <Text style={styles.tdValue}>{Number(item.Sale_Price).toFixed(2)}</Text>
            </View>
            <View style={[styles.td, { width: "80", textAlign: "right" }]}>
              <Text style={styles.tdValue}>{Number(item.Amount).toFixed(2)}</Text>
            </View>
            <View style={[styles.td, { width: "80", textAlign: "right" }]}>
              <Text style={styles.tdValue}>{Number(item.Last_Purchase_Price).toFixed(2)}</Text>
            </View>
            <View style={[styles.td, { width: "80", textAlign: "right" }]}>
              <Text style={styles.tdValue}>{Number(item.Profit).toFixed(2)}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={{ fontSize: "12pt" }}>Profit On Sale</Text>
            <Text style={{fontSize: "10pt" }} >Start Date:{moment(StartDate).format(config.date_format)}   EndDate:{moment(EndDate).format(config.date_format)}</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: "40" }]}>
                <Text style={styles.thValue}>
                  SR
                  <br /> No
                </Text>
              </View>
              <View style={[styles.th, { width: "60" }]}>
                <Text style={styles.thValue}> Dated</Text>
              </View>
              <View style={[styles.th, { width: "80" }]}>
                <Text style={styles.thValue}> Product</Text>
              </View>
              <View style={[styles.th, { width: "80" }]}>
                <Text style={styles.thValue}>Quantity</Text>
              </View>
              <View style={[styles.th, { width: "80", textAlign: "right" }]}>
                <Text style={styles.thValue}>Sale Price</Text>
              </View>
              <View style={[styles.th, { width: "80", textAlign: "right" }]}>
                <Text style={styles.thValue}>Amount</Text>
              </View>
              <View style={[styles.th, { width: "80", textAlign: "right" }]}>
                <Text style={styles.thValue}>Buying Price</Text>
              </View>
              <View style={[styles.th, { width: "80", textAlign: "right" }]}>
                <Text style={styles.thValue}>Profit</Text>
              </View>
            </View>

            {generateData()}
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: "500", textAlign: "right" }]}>
                <Text style={styles.thValue}>Total Sale</Text>
              </View>
              <View style={[styles.th, { width: "80" }]}>
                <Text style={[styles.thValue, { textAlign: "right" }]}>
                {Number(data.TotalSales).toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: "500", textAlign: "right" }]}>
                <Text style={styles.thValue}>Gross Profit On Sale</Text>
              </View>
              <View style={[styles.th, { width: "80" }]}>
                <Text style={[styles.thValue, { textAlign: "right" }]}>
                {Number(data.ProfitOnSales).toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: "500", textAlign: "right" }]}>
                <Text style={styles.thValue}>Total Discounts</Text>
              </View>
              <View style={[styles.th, { width: "80" }]}>
                <Text style={[styles.thValue, { textAlign: "right" }]}>
                {Number(data.TotalDiscount).toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: "500", textAlign: "right" }]}>
                <Text style={styles.thValue}>Total Withdrawal</Text>
              </View>
              <View style={[styles.th, { width: "80" }]}>
                <Text style={[styles.thValue, { textAlign: "right" }]}>
                {Number(data.TotalWithDrawal).toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: "500", textAlign: "right" }]}>
                <Text style={styles.thValue}>Total Expense</Text>
              </View>
              <View style={[styles.th, { width: "80" }]}>
                <Text style={[styles.thValue, { textAlign: "right" }]}>
                {Number(data.TotalExpenes).toFixed(2)}
                </Text>
              </View>
            </View>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: "500", textAlign: "right" }]}>
                <Text style={styles.thValue}>Total Net Gross Profit</Text>
              </View>
              <View style={[styles.th, { width: "80" }]}>
                <Text style={[styles.thValue, { textAlign: "right" }]}>
                {Number(data.GrossProfit).toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {laodng ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintProfitOnSale;
