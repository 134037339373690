import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import ProductsDropDown from "../Shared/ProductsDropDown";
import AccountDropDown from "../Shared/AccountDropDown";
import {
  Row,
  Col,
  message,
  Form,
  Input,
  Select,
  Checkbox,
  Button,
  Skeleton,
  DatePicker,
  Space
} from "antd";

import dayjs from "dayjs";
import { DeleteOutlined, PlusCircleFilled,InfoCircleOutlined } from "@ant-design/icons";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import LoginChecked from "../Shared/LoginChecked";

function EditSales() {
  let navigate = useNavigate();
  let params = useParams();

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [ListofNozzels, setListofNozzels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAddSale, setLoadingAddSale] = useState(false);
  const [WithoutNozzel, setWithoutNozzel] = useState(false);

  const [CashAccountID, setCashAccountID] = useState(0);

  const [Quantity, setQuantity] = useState({});

  const accountTypesArray = AccountDropDown();

  const productTypesArray = ProductsDropDown();
  const [ProductName, setProductName] = useState("");
  const [AccountName, setAccountName] = useState("");
  const [Price, setPrice] = useState(0);

  const [form] = Form.useForm();

  const [purchaseList, setPurchaseList] = useState([]);

  const [priceGroup, setpriceGroup] = useState([]);

  const [totals, setTotals] = useState({
    cashSaleTotal: 0,
    creditSaleTotal: 0,
    totalPrice: 0,
  });

  const [Dated, setDated] = useState(null);
  const [Description, setDescription] = useState("");
  const [InvoiceNo, setInvoiceNo] = useState("");
  const [ListOfSummary, setListOfSummary] = useState([]);
  const [ListofReadings, setListofReadings] = useState([]);

  const goBack=()=>{
    navigate(-1)
  }

  useEffect(() => {
    if (accountTypesArray) {
      let cash = accountTypesArray.find((item) => item.label === "Cash");
      if (cash) {
        setCashAccountID(cash.value);
      }
    }

    //console.log(accountTypesArray);
  }, [accountTypesArray]);

  useEffect(() => {
    document.title = "Edit Sale";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Sale/GetSaleByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    console.log("data", data);

    axios(api_config)
      .then(function (response) {
        console.log("response", response.data);
        if (response.data.status_code == 1) {
          // Handle success

          let listOfSale = response.data.listofSale;

          const result = listOfSale.map((item) => ({
            ProductName: item.Product_BE?.Name,
            AccountName: item.Account_BE?.Name,
            Product_ID: item.Product_ID,
            Account_ID: item.Account_ID,
            Vehicle_No: item.Vehicle_No,
            Receipt_Number: item.Receipt_No,
            Quantity: item.Quantity,
            Price: item.Price,
            Is_Cash: item.Is_Cash,
          }));

          setInvoiceNo(response.data.InvoiceNo);
          setPurchaseList(result);
          setDescription(response.data.Description);
          setDated(dayjs(response.data.Dated));
          setListofNozzels(response.data.listofReadings);

          setLoading(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //     document.title = "Edit Sale";
  //     setLoading(true);
  //     const data = {
  //         UserID: UserID,
  //         AccessKey: AccessKey,
  //     };
  //     var api_config = {
  //         method: "post",
  //         url: config.base_url + "Sale/GetNextSaleInvoiceNo",
  //         headers: {
  //             "Content-Type": "application/json",
  //         },
  //         data: data,
  //     };

  //     axios(api_config)
  //         .then(function (response) {
  //             console.log("nozz", response.data);
  //             if (response.data.status_code == 1) {
  //                 // Handle success
  //                 setLoading(false);
  //                 setListofNozzels(response.data.ListofReading);
  //                 setInvoiceNo(response.data.NextInvoiceNo);
  //             } else {
  //                 // Handle error
  //             }
  //             //   setLoading(false);
  //         })
  //         .catch(function (error) {
  //             console.log(error);
  //         });
  // }, []);

  useEffect(() => {
    const groupedData = purchaseList.reduce((acc, obj) => {
      const key = obj.Product_ID;
      if (!acc[key]) {
        acc[key] = {
          Product_ID: obj.Product_ID,
          ProductName: obj.ProductName,
          Total_Price: 0,
        };
      }
      acc[key].Total_Price += parseInt(obj.Quantity) * parseInt(obj.Price);
      return acc;
    }, {});

    setpriceGroup(Object.values(groupedData));
  }, [purchaseList]);

  useEffect(() => {
    const cashSaleTotal = purchaseList.reduce((acc, obj) => {
      if (obj.Is_Cash) {
        return acc + parseFloat(obj.Quantity) * parseFloat(obj.Price);
      }
      return acc;
    }, 0);

    const creditSaleTotal = purchaseList.reduce((acc, obj) => {
      if (!obj.Is_Cash) {
        return acc + parseFloat(obj.Quantity) * parseFloat(obj.Price);
      }
      return acc;
    }, 0);

    const totalPrice = purchaseList.reduce((acc, obj) => {
      return acc + parseFloat(obj.Quantity) * parseFloat(obj.Price);
    }, 0);

    setTotals({
      cashSaleTotal,
      creditSaleTotal,
      totalPrice,
    });
  }, [purchaseList]);

  useEffect(() => {
    // Convert Price and Quantity to numbers and calculate the total whenever they change
    const priceValue = parseFloat(Price);
    const quantityValue = parseFloat(Quantity);
    const totalValue = priceValue * quantityValue;
    const total = isNaN(totalValue) ? "" : totalValue.toString();
    form.setFieldsValue({
      total: total,
    });
  }, [Price, Quantity]);

  function handleCheckboxChange(index) {
    setPurchaseList((prevData) => {
      const setProduct_ID = [...prevData];
      setProduct_ID[index].Is_Cash = !setProduct_ID[index].Is_Cash;
      return setProduct_ID;
    });
  }

  const handleSubmit = (formData) => {
    formData["Is_Cash"] = formData["Is_Cash"] == "on" ? true : false;
    formData["Vehicle_No"] =
      formData["Vehicle_No"] == undefined ? "" : formData["Vehicle_No"];
    formData["Receipt_No"] =
      formData["Receipt_No"] == undefined ? "" : formData["Receipt_No"];

    const data = {
      ProductName: ProductName,
      AccountName: AccountName,
      ...formData,
    };

    setPurchaseList((prevPurchaseList) => {
      const newPurchaseList = [...prevPurchaseList, data];

      return newPurchaseList;
    });

    form.resetFields();
  };

  useEffect(() => {
    // Step 1: Reduce purchaseList to get the total quantity sold for each product
    const reducedData = Object.values(
      purchaseList.reduce((acc, item) => {
        if (!acc[item.ProductName]) {
          acc[item.ProductName] = {
            ProductName: item.ProductName,
            Price: item.Price,
            Quantity: 0,
          };
        }
        acc[item.ProductName].Quantity += parseFloat(item.Quantity);
        return acc;
      }, {})
    );

    //console.log('reducedData', reducedData);

    const nozzel = ListofNozzels.find(
      (item) => item.ProductName === ProductName
    );

    if (nozzel) {
      // Step 2: Calculate total sales for each product from ListofNozzels
      const productTotals = {};
      ListofNozzels.forEach((item) => {
        const productName = item.ProductName;
        const totalSale = parseFloat(
          item.Current_Reading - item.Last_Reading - item.Returned || 0
        );

        if (productName in productTotals) {
          productTotals[productName] += totalSale;
        } else {
          productTotals[productName] = totalSale;
        }
      });

      // Convert the productTotals object to an array
      const productTotalsArray = Object.entries(productTotals).map(
        ([ProductName, TotalSale]) => ({
          ProductName,
          TotalSale,
        })
      );

      //console.log('productTotalsArray', productTotalsArray);
      //console.log('purchaseList', purchaseList);

      // Step 3: Calculate the summary array
      const summaryArray = productTotalsArray.map((productTotal) => {
        const soldProduct = reducedData.find(
          (item) => item.ProductName === productTotal.ProductName
        );
        const totalSold = soldProduct ? soldProduct.Quantity : 0;
        const price = soldProduct ? soldProduct.Price : 0;
        return {
          ProductName: productTotal.ProductName,
          TotalSale: productTotal.TotalSale,
          TotalSold: totalSold,
          Price: price,
          PendingQuantity: productTotal.TotalSale - totalSold,
        };
      });

      setListOfSummary(summaryArray);

      console.log("summaryArray", summaryArray);
    } else {
      console.log("no nozzel");
    }
  }, [purchaseList, ListofNozzels, ProductName]);

  const handleTotalChange = () => {
    const total = form.getFieldValue("Total");
    const price = form.getFieldValue("Price");
    if (total && price) {
      const quantity = total / price;
      form.setFieldsValue({ Quantity: quantity.toFixed(2) });
    }
  };

  function handleCheckboxChange(index) {
    setPurchaseList((prevData) => {
      const newData = [...prevData];
      newData[index].Is_Cash = !newData[index].Is_Cash;
      return newData;
    });
  }

  useEffect(() => {
    // Convert Price and Quantity to numbers and calculate the total whenever they change
    const priceValue = parseFloat(Price);
    const quantityValue = parseFloat(Quantity);
    const totalValue = priceValue * quantityValue;
    const total = isNaN(totalValue) ? "" : totalValue.toString();
    form.setFieldsValue({
      total: total,
    });
  }, [Price, Quantity]);

  const handleQuantity = (e) => {
    setQuantity(e.target.value);

    const quantity = form.getFieldValue("Quantity");
    const price = form.getFieldValue("Price");
    if (quantity && price) {
      const total = quantity * price;
      form.setFieldsValue({ Total: total.toFixed(2) });
    }
  };

  const handlePrice = (e) => {
    setPrice(e.target.value);
  };

  const handleProductChange = (value, option) => {
    setProductName(option.label);
    form.setFieldValue("Price", option.price);
  };
  const handleAccountChange = (value, option) => {
    //console.log(value);
    setAccountName(option.label);
  };

  const handleLastReadingChange = (index, value) => {
    const newList = [...ListofNozzels];
    newList[index].Last_Reading = parseFloat(value) || 0;
    setListofNozzels(newList);

    const updatedList = [...ListofReadings];
    const existingIndex = updatedList.findIndex(
      (item) => item.PumpMachineID === ListofNozzels[index].PumpMachineID
    );

    if (existingIndex !== -1) {
      // If the item already exists, update its Current_Reading value
      updatedList[existingIndex].Last_Reading = value;
    } else {
      // If the item doesn't exist, add a new item to ListofReadings
      updatedList.push({
        PumpMachineID: ListofNozzels[index].PumpMachineID,
        Last_Reading: value,
        Current_Reading: ListofNozzels[index].Current_Reading,
        Returned: ListofNozzels[index].Returned,
      });
    }

    // console.log(updatedList);

    // Update the state of ListofReadings
    setListofReadings(updatedList);
  };

  const handleCurrentReadingChange = (index, parameter, value) => {
    const newList = [...ListofNozzels];
    newList[index].Current_Reading = parseFloat(value) || 0;
    setListofNozzels(newList);

    const updatedList = [...ListofReadings];
    const existingIndex = updatedList.findIndex(
      (item) => item.PumpMachineID === ListofNozzels[index].PumpMachineID
    );

    if (existingIndex !== -1) {
      // If the item already exists, update its Current_Reading value
      updatedList[existingIndex].Current_Reading = value;
    } else {
      // If the item doesn't exist, add a new item to ListofReadings
      updatedList.push({
        PumpMachineID: ListofNozzels[index].PumpMachineID,
        Last_Reading: ListofNozzels[index].Current_Reading,
        Current_Reading: value,
        Returned: ListofNozzels[index].Returned,
      });
    }

    //console.log(updatedList);

    // Update the state of ListofReadings
    setListofReadings(updatedList);
  };

  const handleReturnedChange = (index, value) => {
    const newList = [...ListofNozzels];
    newList[index].Returned = parseFloat(value) || 0;
    setListofNozzels(newList);

    const updatedList = [...ListofReadings];
    const existingIndex = updatedList.findIndex(
      (item) => item.PumpMachineID === ListofNozzels[index].PumpMachineID
    );

    if (existingIndex !== -1) {
      // If the item already exists, update its Current_Reading value
      updatedList[existingIndex].Returned = value;
    } else {
      // If the item doesn't exist, add a new item to ListofReadings
      updatedList.push({
        PumpMachineID: ListofNozzels[index].PumpMachineID,
        Last_Reading: ListofNozzels[index].Last_Reading,
        Current_Reading: ListofNozzels[index].Current_Reading,
        Returned: value,
      });
    }

    // Update the state of ListofReadings
    setListofReadings(updatedList);
  };

  const calculateQuantity = (item) => {
    return item.Current_Reading - item.Last_Reading - item.Returned;
  };

  const handleDate = (date, value) => {
    setDated(value);
  };

  const renderReadings = () => {
    return ListofNozzels.map((item, index) => (
      <tr key={index}>
        <td>{item.Pump_No}</td>
        <td>{item.ProductName}</td>
        <td>
  <input
    value={item.Last_Reading}
    onChange={(e) => handleLastReadingChange(index, e.target.value)}
    style={{
      border: "none",
      outline: "none",
      width: "100%", 
    }}
  />
</td>
        <td>
          <input
            value={item.Current_Reading}
            onChange={(e) =>
              handleCurrentReadingChange(
                index,
                "Current_Reading",
                e.target.value
              )
            }
            style={{
              border: "none",
              outline: "none",
              width: "100%", 
            }}
          />
        </td>
        <td>
          <input
            value={item.Returned}
            onChange={(e) => handleReturnedChange(index, e.target.value)}
            style={{
              border: "none",
              outline: "none",
              width: "80px", 
            }}
          />
        </td>
        <td>{calculateQuantity(item)}</td>
      </tr>
    ));
  };

  const renderTotals = () => {
    // Create a map to store the total sales for each product
    const productTotals = {};

    // Accumulate total sales for each product
    ListofNozzels.forEach((item) => {
      const productName = item.ProductName;
      const totalSale = parseFloat(
        item.Current_Reading - item.Last_Reading - item.Returned || 0
      );

      if (productName in productTotals) {
        productTotals[productName] += totalSale;
      } else {
        productTotals[productName] = totalSale;
      }
    });

    // Render total rows
    const totalRows = Object.keys(productTotals).map((productName, index) => (
      <li key={`total-${index}`}>
        <p>{productName}</p>
        <h3>{productTotals[productName]} Ltr.</h3>
      </li>
    ));

    return totalRows;
  };

  const handleSaveInvoice = () => {
    if (Dated == null) {
      console.log(Dated);
      message.error("Please Select Date");
      return;
    }

    setLoadingAddSale(true);
    const modifiedData = purchaseList.map(
      ({ AccountName, ProductName, ...rest }) => rest
    );

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Dated: Dated,
      Description: Description,
      ListofReadings: ListofReadings,
      ListofSaleDetails: modifiedData,
      ID: params.id,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Sale/UpdateSale",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        //console.log("add sale", response.data);
        if (response.data.status_code == 1) {
          // Handle success
          message.success(response.data.status_message);
          setLoadingAddSale(false);
          //navigate('/sales/view-sales');
        } else {
          // Handle error
          setLoadingAddSale(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onChangeWithoutNozzelCheck = (e) => {
    setWithoutNozzel(e.target.checked);
  };

  const handleDelete = (index) => {
    const updatedItems = [...purchaseList];
    // Remove the item at the specified index
    updatedItems.splice(index, 1);
    // Update the state with the new array
    setPurchaseList(updatedItems);
  };

  const AddCashSale = () => {
    const productTotalsArray = [];

    // Accumulate total sales for each product
    ListofNozzels.forEach((item) => {
      const productName = item.ProductName;
      const totalSale = parseFloat(
        item.Current_Reading - item.Last_Reading - item.Returned || 0
      );

      const productIndex = productTotalsArray.findIndex(
        (product) => product.ProductName === productName
      );

      if (productIndex > -1) {
        productTotalsArray[productIndex].totalSale += totalSale;
      } else {
        productTotalsArray.push({
          Price: item.Last_Purchase_Price,
          ProductID: item.ProductID,
          ProductName: productName,
          totalSale,
        });
      }
    });

    const groupedTotalsArray = [];

    purchaseList.forEach((item) => {
      const { Product_ID, Price, Quantity, ProductName } = item;
      const quantity = parseFloat(Quantity);

      const existingProductIndex = groupedTotalsArray.findIndex(
        (product) => product.Product_ID === Product_ID
      );

      if (existingProductIndex > -1) {
        groupedTotalsArray[existingProductIndex].TotalQuantity += quantity;
      } else {
        groupedTotalsArray.push({
          Product_ID,
          ProductName,
          TotalPrice: Price,
          TotalQuantity: quantity,
        });
      }
    });

    const mergedArray = [];

    groupedTotalsArray.forEach((groupedItem) => {
      const matchingProduct = productTotalsArray.find(
        (product) => product.ProductName === groupedItem.ProductName
      );

      const remainingQuantity = matchingProduct
        ? matchingProduct.totalSale - groupedItem.TotalQuantity
        : 0;

      mergedArray.push({
        ...groupedItem,
        Price: matchingProduct ? matchingProduct.Price : 0,
        totalSale: matchingProduct ? matchingProduct.totalSale : 0,
        remainingQuantity: remainingQuantity,
      });
    });

    productTotalsArray.forEach((productItem) => {
      const isProductInGrouped = groupedTotalsArray.some(
        (groupedItem) => groupedItem.ProductName === productItem.ProductName
      );

      if (!isProductInGrouped) {
        mergedArray.push({
          Product_ID: productItem.ProductID,
          ProductName: productItem.ProductName,
          TotalPrice: 0,
          TotalQuantity: 0,
          totalSale: productItem.totalSale,
          remainingQuantity: productItem.totalSale,
          Price: productItem.Price,
        });
      }
    });

    //console.log('groupedTotalsArray', groupedTotalsArray);
    //console.log('productTotalsArray', productTotalsArray);
    //console.log('mergedArray', mergedArray);

    const newPurchaseList = mergedArray.map((item) => ({
      ProductName: item.ProductName,
      AccountName: "Cash",
      Product_ID: item.Product_ID,
      Account_ID: CashAccountID,
      Vehicle_No: "",
      Receipt_No: "",
      Quantity: item.remainingQuantity,
      Price: item.Price,
      Is_Cash: false,
    }));

    //console.log('newPurchaseList', newPurchaseList);

    setPurchaseList((prevList) => [...prevList, ...newPurchaseList]);
  };

  return (
    <>
      <LoginChecked />

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink onClick={goBack}>
                <ChevronLeftIcon />
              </NavLink>
              Edit Sale
            </h3>
          </div>

          {loading ? (
            <>
              <Row gutter={[34, 0]}>
                <Col xs={24} md={16}>
                  <Skeleton active />
                  <Skeleton active />
                </Col>
                <Col xs={24} md={8}>
                  <Skeleton active />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row gutter={[24, 10]}>
                <Col md={3} xs={12}>
                  <Form.Item className="input-vertical" label="Invoice No">
                    <Input value={InvoiceNo} readOnly />
                  </Form.Item>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item className="input-vertical" label="Date">
                    <DatePicker
                      defaultValue={Dated}
                      style={{ width: "100%" }}
                      onChange={handleDate}
                    />
                  </Form.Item>
                </Col>
                <Col md={17} xs={24}>
                  <Form.Item className="input-vertical" label="Description">
                    <Input
                      value={Description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <h4 style={{ marginBottom: "10px" }}>
                Step 1 Enter Pump Readings
              </h4>
              <div style={{ marginBottom: "20px" }}>
                <Space>
                <InfoCircleOutlined style={{ color: 'blue' }} />
                if you want to change the reading Delete and Add new Invoice
                </Space>
             
              </div>
              {!WithoutNozzel && (
                <div className="sale-wrap">
                  <div className="table-responsive">
                    <table className="add-sale-table">
                      <thead>
                        <tr>
                          <th>Pump No.</th>
                          <th>Product</th>
                          <th>Last Reading</th>
                          <th>Current Reading</th>
                          <th>Return</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>{renderReadings()}</tbody>
                    </table>
                  </div>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h4>Step 2 Enter Credit Sale Details </h4>
              </div>

              <Form onFinish={handleSubmit} form={form}>
                <div className="sale-wrap">
                  <div className="table-responsive">
                    <table className="add-sale-table">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Product</th>
                          <th>Account</th>
                          <th>Vehicle No</th>
                          <th>Receipt No </th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Cash</th>
                          <th>Total </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchaseList.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.ProductName}</td>
                            <td>{item.AccountName}</td>
                            <td>{item.Vehicle_No}</td>
                            <td>{item.Receipt_No}</td>
                            <td>{item.Quantity}</td>
                            <td>{item.Price}</td>

                            <td>
                              {(
                                parseFloat(item.Price) *
                                parseFloat(item.Quantity)
                              ).toFixed(2)}
                            </td>
                            <td>
                              <Checkbox
                                checked={item.Is_Cash}
                                onChange={() => handleCheckboxChange(index)}
                              />
                            </td>
                            <td>
                              <DeleteOutlined
                                onClick={(e) => handleDelete(index)}
                              />
                            </td>
                          </tr>
                        ))}
                        <tr className="table-form">
                          <td></td>
                          <td>
                            <Form.Item
                              name="Product_ID"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select a product!",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                variant="borderless"
                                placeholder="Select Product"
                                loading={
                                  productTypesArray === false ? true : false
                                }
                                options={productTypesArray}
                                onChange={handleProductChange}
                                style={{ width: "180px" }}
                                optionFilterProp="label" // Specify the property to be used for filtering
                                filterOption={(input, option) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name="Account_ID"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select an account!",
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Select account "
                                optionFilterProp="children"
                                onChange={handleAccountChange}
                                style={{ width: "200px" }}
                                // onSearch={onSearch}

                                loading={
                                  accountTypesArray === false ? true : false
                                }
                                options={accountTypesArray}
                                filterOption={(input, option) =>
                                  option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item name="Vehicle_No">
                              <Input
                                style={{ width: "100px" }}
                                placeholder="Vehicle Number"
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item name="Receipt_No">
                              <Input placeholder="Receipt Number" />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name="Quantity"
                              rules={[
                                {
                                  required: true,
                                  message: "Quantity",
                                },
                              ]}
                            >
                              <Input
                                onChange={handleQuantity}
                                placeholder="Quantity"
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item
                              name="Price"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter price!",
                                },
                              ]}
                            >
                              <Input
                                onChange={handlePrice}
                                placeholder="Price"
                              />
                            </Form.Item>
                          </td>

                          <td>
                            <Form.Item name="Total">
                              <Input
                                onChange={handleTotalChange}
                                placeholder="Total"
                              />
                            </Form.Item>
                          </td>
                          <td>
                            <Form.Item name="Is_Cash">
                              <Checkbox>Cash</Checkbox>
                            </Form.Item>
                          </td>
                          <td>
                            <Button
                              type="primary"
                              shape="round"
                              htmlType="submit"
                              size="small"
                            >
                              Add
                            </Button>
                          </td>
                        </tr>

                        {totals.totalPrice > 0 && (
                          <>
                            <tr>
                              <td colSpan={8} style={{ textAlign: "right" }}>
                                Total Sale
                              </td>
                              <td colSpan={2}>
                                <strong>{totals.totalPrice.toFixed(2)}</strong>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={8} style={{ textAlign: "right" }}>
                                Total Cash Sale
                              </td>
                              <td colSpan={2}>
                                {totals.cashSaleTotal.toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={8} style={{ textAlign: "right" }}>
                                Total Credit Sale
                              </td>
                              <td>{totals.creditSaleTotal.toFixed(2)}</td>
                              <td></td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Form>

              <div
                style={{
                  justifyContent: "flex-end",
                  marginTop: "30px",
                  display: "flex",
                  gap: "10px",
                }}
              >
                <Button
                  type="default"
                  size="large"
                  shape="round"
                  onClick={AddCashSale}
                >
                  Add Cash Sale
                </Button>
                <Button
                  onClick={handleSaveInvoice}
                  type="primary"
                  size="large"
                  shape="round"
                  loading={loadingAddSale}
                >
                  Save Invoice
                </Button>
              </div>

              <div>
                <h4>Sale Summary </h4>
              </div>

              <div className="sale-wrap">
                <div className="table-responsive">
                  <table className="add-sale-table">
                    <thead>
                      <tr>
                        <th>SR</th>
                        <th>Product</th>
                        <th>Price / Ltr.</th>
                        <th>Out From Machine</th>
                        <th>Total Cost</th>
                        <th>Sale Qty</th>
                        <th>Sale Cost</th>

                        <th>Remaining Qty</th>
                        <th>Remaining Sale</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ListOfSummary.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.ProductName}</td>
                          <td>{item.Price}</td>
                          <td>{item.TotalSale}</td>
                          <td>{item.TotalSale * item.Price}</td>
                          <td>{item.TotalSold}</td>
                          <td>{item.TotalSold * item.Price}</td>

                          <td>{item.PendingQuantity}</td>
                          <td>
                            {item.TotalSale * item.Price -
                              item.TotalSold * item.Price}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div style={{ height: "200px" }}></div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default EditSales;
