import React, { useEffect, useState } from "react";
import config from "../../config";
import axios from "axios";
import LoginChecked from "../Shared/LoginChecked";
import BoxCard from "../Shared/BoxCard";
import {
  Row,
  Col,
  Card,
  Modal,
  Table,
  Statistic,
  Form,
  Popconfirm,
  Pagination,
  Input,
  Select,
  DatePicker,
  Dropdown,
  Space,
  Button,
  message,
  Spin,
  Breadcrumb,
  Drawer,
  Tooltip,
} from "antd";
import moment from "moment";
import AccountTypesDropdown from "../Shared/AccountTypesDropdown";
import {
  PrinterOutlined,
  EditOutlined,
  PlusOutlined,
  DownOutlined,
  IdcardOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import AccountDropDown from "../Shared/AccountDropDown";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";

function ManageVouchers() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [Data, setData] = useState("");
  const [StartDate, SetStartDate] = useState("");
  const [EndDate, SetEndDate] = useState("");
  const [OrderBy, SetOrderBy] = useState("1");
  const [AccountID, SetAccountID] = useState(0);

  const { RangePicker } = DatePicker;

  const [VoucherType, SetVoucherType] = useState("");
  const [DebitAccountID, SetDebitAccountID] = useState(0);
  const [CreditAccountID, SetCreditAccountID] = useState(0);

  const [listofVouchers, SetlistofVouchers] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [IsVoucherUpdated, setIsVoucherUpdated] = useState(false);
  const [Button_Lodding, setButton_Lodding] = useState(false);
  const accounts = AccountDropDown();
  const [currentPage, setCurrentPage] = useState(1);

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const accountTypesArray = AccountTypesDropdown();
  const [TotalVouchers, setTotalVouchers] = useState("");
  const [DebitCount, setDebitCount] = useState("");
  const [CreditCount, setCreditCount] = useState("");
  const [Total, setTotal] = useState("");
  const [listofAccounts, setlistofAccounts] = useState([]);
  const [debitAccountID, setdebitAccountID] = useState(0);
  const [creditAccountID, setcreditAccountID] = useState(0);

  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [Dated, setDated] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    document.title = "Manage Vouchers";
    setIsDeleted(false);
    setIsVoucherUpdated(false);
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      OrderBy: OrderBy,
      PageNo: pageNo,
      PageSize: pageSize,
      AccountID: AccountID,
      VoucherType: VoucherType,
      Debit_Account_ID: DebitAccountID,
      Credit_Account_ID: creditAccountID,
    };

    console.log("data----", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/GetVouchers",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log("vouchers list", response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofVouchers(response.data.listofVouchers);
          setTotalVouchers(response.data.TotalVouchers);
          setDebitCount(response.data.DebitCount);
          setCreditCount(response.data.CreditCount);
          setTotal(response.data.Total);
          setTotalRecords(response.data.totalRecords);
          setData(response.data);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [
    DebitAccountID,
    creditAccountID,
    AccountID,
    OrderBy,
    VoucherType,
    IsVoucherUpdated,
    StartDate,
    EndDate,
    pageSize,
    pageNo,
    isDeleted,
  ]);

  useEffect(() => {
    setLoadingAccounts(false);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      OrderBy: 1,
      PageNo: 1,
      PageSize: 10000,
      BalanceType: "",
      Account_Type_ID: "",
      Dated: Dated,
      Name: "",
    };

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccounts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log("accounts", response.data);
        if (response.data.status_code == 1) {
          // Handle success

          let list = response.data.listofAccounts;
          const result = list.map((item) => ({
            value: item.ID,
            label: item.Name,
          }));

          setlistofAccounts(result);

          setLoadingAccounts(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleTableChange = async (pagination, filters, sorter) => {
    // Make API request with sorting parameters
    const sortField = sorter.field;
    const sortOrder = sorter.order === "descend" ? "desc" : "asc";

    if (sortField == "Dated") {
      if (sortOrder == "asc") {
        SetOrderBy(6);
      } else {
        SetOrderBy(5);
      }
    } else if (sortField == "VoucherNo") {
      if (sortOrder == "asc") {
        SetOrderBy(2);
      } else {
        SetOrderBy(1);
      }
    }

    console.log("date order", sortField);
    console.log("no order", sortOrder);
  };

  const handelDelete = (ID) => {
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: ID,
    };
    console.log("data", data);
    const api_config = {
      method: "post",
      url: config.base_url + "Voucher/DeleteVoucher",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log("response", response.data);
        if (response.data.status_code === 1) {
          setIsDeleted(true);
          setLoading(false);
          message.success(response.data.status_message);
        } else {
          setLoading(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "SR",
      render: (_, record, index) => index + 1 + pageSize * (pageNo - 1),
      key: "Sr",
    },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "dated",
      render: (date) => moment(date).format(config.date_format),
      sorter: true,
    },
    {
      title: "No",
      dataIndex: "VoucherNo",
      key: "VoucherNo",
      sorter: true,
    },
    {
      title: "Debit Account",
      dataIndex: "Debit_Account",
      key: "Debit_Account",
      render: (_, record) => {
        return record.Debit_Account ? (
          <a
            target="_blank"
            href={`/accounts/account-ledger/?account_id=${record.Debit_Account_ID}`}
          >
            {record.Debit_Account.Name}
          </a>
        ) : (
          ""
        );
      },
    },

    {
      title: "Credit Account",
      dataIndex: "Credit_Account",
      key: "Credit_Account",
      render: (_, record) => {
        return record.Credit_Account ? (
          <a
            target="_blank"
            href={`/accounts/account-ledger/?account_id=${record.Credit_Account_ID}`}
          >
            {record.Credit_Account.Name}
          </a>
        ) : (
          ""
        );
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "description",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      align: "right",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => showModal(record)}>
            <Tooltip title="Edit Voucher">
              <EditOutlined />
            </Tooltip>
          </a>
          <Popconfirm
            title="Sure to delete?"
            discription="Are You sure to delete this data"
            onConfirm={(e) => handelDelete(record.ID)}
          >
            <NavLink to={`#`}>
              <Tooltip title="Delete Voucher">
                <DeleteOutlined />
              </Tooltip>
            </NavLink>
          </Popconfirm>
          <NavLink
            to={`/accounts/print-voucher-list/?voucher_id=${record.ID}`}
            target="_blank"
          >
            <Tooltip title="Print Voucher">
              <PrinterOutlined />
            </Tooltip>
          </NavLink>
        </Space>
      ),
    },
  ];

  const onPageChange = (page) => {
    console.log("PageNo", page);
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1); // Reset to the first page when changing page size
    setPageSize(size);
    console.log(size);
  };

  const Update_Voucher = (formData) => {
    setButton_Lodding(true);
    setLoading(true);

    formData["Dated"] = dayjs(formData["Dated"]).format("YYYY-MM-DD");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: selectedVoucher.ID,
      ...formData,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/UpdateVoucher",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setIsVoucherUpdated(true);
          setButton_Lodding(false);
          setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          setConfirmLoading(true);
          setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
          }, 2000);
        } else {
          // Handle error
          setLoading(false);
          setButton_Lodding(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const showModal = (record) => {
    const editVoucher = {
      ID: record.ID,
      voucherNo: record.VoucherNo,
      Amount: record.Amount,
      Dated: dayjs(record.Dated),
      Debit_Account_ID: record.Debit_Account?.ID,
      Credit_Account_ID: record.Credit_Account?.ID,
      Description: record.Description,
    };

    console.log("recorde", record);

    setSelectedVoucher(editVoucher);
    setOpen(true);
  };

  const handleCancel = () => {
    setSelectedVoucher(null);
    setOpen(false);
  };

  const onSelectDebitAccount = (item) => {
    setdebitAccountID(item);
  };
  const onSelectCreditAccount = (item) => {
    setcreditAccountID(item);
  };

  const handleAccountChange = (value) => {
    SetAccountID(value);
  };

  const items = [
    {
      key: "1",
      label: <NavLink to="/vouchers/add-voucher">General</NavLink>,
      icon: <IdcardOutlined />,
    },
    {
      key: "2",
      label: (
        <NavLink to="/vouchers/payment-received">Payment Received</NavLink>
      ),
      icon: <ArrowDownOutlined />,
    },
    {
      key: "3",
      label: <NavLink to="/vouchers/payment-given">Payment Given</NavLink>,
      icon: <ArrowUpOutlined />,
    },

    {
      key: "4",
      label: <NavLink to="/vouchers/expense">Expense</NavLink>,
      icon: <PlusOutlined />,
    },
  ];

  const handleDateChange = (date, value) => {
    console.log(value);
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };
  const disableDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <LoginChecked />
      <Modal
        title="Update Voucher"
        open={open}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null} // This will hide the footer buttons (OK and Cancel)
      >
        <Form
          name="basic"
          layout="vertical"
          onFinish={Update_Voucher}
          autoComplete="off"
          initialValues={selectedVoucher}
          fields={[
            {
              name: "voucherNo",
              value: selectedVoucher?.voucherNo,
            },
            // {
            //   name: "Dated",
            //   value: selectedVoucher?.Dated,
            // },
            {
              name: "Amount",
              value: selectedVoucher?.Amount,
            },
            {
              name: "Description",
              value: selectedVoucher?.Description,
            },
            {
              name: "Debit_Account_ID",
              value: selectedVoucher?.Debit_Account_ID,
            },
            {
              name: "Credit_Account_ID",
              value: selectedVoucher?.Credit_Account_ID,
            },
          ]}
        >
          <Row gutter={[16, 3]}>
            <Col span={8}>
              <Form.Item name="voucherNo" label="Voucher No">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="Amount" label="Amount">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="Dated" label="Date">
                <RangePicker allowClear onChange={handleDateChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Debit Account"
                name="Debit_Account_ID"
                className="input-vertical"
              >
                <Select
                  showSearch
                  placeholder="Select Account"
                  optionFilterProp="children"
                  // onChange={onSelectDebitAccount}
                  loading={loadingAccounts}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  //loading={accounts === false ? true : false}
                  options={accounts}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Credit Account"
                name="Credit_Account_ID"
                className="input-vertical"
              >
                <Select
                  showSearch
                  placeholder="Select Account"
                  optionFilterProp="children"
                  loading={loadingAccounts}
                  // onChange={onSelectCreditAccount}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  // loading={accounts === false ? true : false}
                  options={accounts}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="Description" label="Description">
                <Input />
              </Form.Item>
            </Col>

            {/* <Col span={12}>
              <Checkbox>Send Email</Checkbox>
            </Col> */}
            <Col span={24}>
              <div className="text-right">
                <Space wrap>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    loading={Button_Lodding}
                    htmlType="submit"
                  >
                    Update Voucher
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Vouchers</h3>
            <div className="header-actions">
              <NavLink
                to={`/vouchers/print-voucher-details/?StartDate=${StartDate}&EndDate=${EndDate}&AccountID=${AccountID}&VoucherType=${VoucherType}&Debit_Account_ID=${DebitAccountID}&Credit_Account_ID=${creditAccountID}`}
                target="blank"
              >
                <Button
                  type="dashed"
                  shape="round"
                  size="large"
                  icon={<PrinterOutlined />}
                >
                  Download / Print
                </Button>
              </NavLink>
              <Dropdown
                menu={{
                  items,
                }}
              >
                <Button type="primary" size="large" shape="round">
                  <Space>
                    Add Voucher
                    <DownOutlined style={{ color: "white" }} />
                  </Space>
                </Button>
              </Dropdown>
            </div>
          </div>

          <div className="card-total-detal">
            <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Total Vouchers"
                  amount={Data.TotalVouchers}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="#6FB2FF"
                      class="size-6"
                      viewBox="0 0 30 30"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Debits Count"
                  amount={Data.DebitCount}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="#6FB2FF"
                      class="size-6"
                      viewBox="0 0 30 30"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Credits Count"
                  amount={Data.CreditCount}
                  icon={
                    <svg
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#6FB2FF"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Total Amount"
                  prefix="Rs."
                  amount={Data.Total}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="#6FB2FF"
                      class="size-6"
                      viewBox="0 0 30 30"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                      />
                    </svg>
                  }
                />
              </Col>
            </Row>
          </div>

          <div className="filters-wrap">
            <Form>
              <Form.Item name="">
                <RangePicker
                  style={{
                    width: "100%",
                    marginLeft: 5,
                  }}
                  onChange={handleDateChange}
                  disabledDate={disableDate}
                />
              </Form.Item>

              <Form.Item name="">
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: "160px",
                  }}
                  placeholder="Account"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={accounts ? false : true}
                  options={accounts}
                  onChange={handleAccountChange}
                />
              </Form.Item>

              <Form.Item name="VoucherType">
                <Select
                  allowClear
                  style={{
                    width: "160px",
                  }}
                  placeholder="Voucher Type"
                  onChange={(value) => SetVoucherType(value)}
                  options={[
                    {
                      value: "Received Amount",
                      label: "Received Amount",
                    },
                    {
                      value: "Paid Amount",
                      label: "Paid Amount",
                    },
                    {
                      value: "Expenses",
                      label: "Expenses",
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item name="Debit_Account">
                <Select
                  allowClear
                  showSearch
                  style={
                    {
                      // width: '80%',
                    }
                  }
                  placeholder="Debit Account"
                  onChange={(value) => SetDebitAccountID(value)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={accounts ? false : true}
                  options={accounts}
                />
              </Form.Item>

              <Form.Item name="Credit_Account">
                <Select
                  showSearch
                  allowClear
                  style={
                    {
                      // width: '80%',
                    }
                  }
                  placeholder="Credit Account"
                  onChange={(value) => setcreditAccountID(value)}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={accounts ? false : true}
                  options={accounts}
                />
              </Form.Item>
            </Form>
          </div>

          {/* --------------------------------------------Table---------------------------------------- */}
          <br />

          <Table
            size="small"
            columns={columns}
            dataSource={listofVouchers}
            pagination={false}
            loading={loading}
            onChange={handleTableChange}
            bordered
            scroll={{
              x: "100%",
            }}
          />
          <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange} // Handle page size change
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageVouchers;
