import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../../config";
import TradingHeader from "./PrintHeaderAccount";
import dayjs from "dayjs";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { Spin } from "antd";

function PrintTradingReport() {
  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  const urlParams = new URLSearchParams(window.location.search);
  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");

  const [tradingList, setTradingList] = useState({});

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };
    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetTradingAccountReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log("Pur", response.data);
        if (response.data.status_code == 1) {
          setTradingList(response.data);

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const formattedStartDate = dayjs(StartDate).format("DD-MM-YYYY");
  const formattedEndDate = dayjs(EndDate).format("DD-MM-YYYY");

  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
      //flexGrow: 1
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    table: {
      display: "table",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    th: {
      flex: 1,
      padding: 10,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    thValue: {
      fontSize: "8pt",
      fontWeight: "bold",
    },

    row: {
      flexDirection: "row",
    },
    td: {
      flex: 1,
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    tdValue: {
      fontSize: "8pt",
      color: "black",
    },
    container: {
      flexDirection: "row",
      padding: "5px 5px",
      alignItems: "flex-start",
      border: "0.8px solid grey",
      display: "flex",
      alignItems: "center",
    },
    label: {
      width: 200,
    },
    data: {
      flex: 1,
      marginLeft: 10,
    },
    text: {
      fontSize: 10,
    },
  });
  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <TradingHeader />
        <View style={styles.section}>
          <View
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <View style={styles.title}>
              <Text style={{ fontSize: "12pt" }}>Trading Sheet</Text>
            </View>
            <View>
              <Text style={{ fontSize: "11pt", marginBottom: "10px" }}>
                {formattedStartDate} To {formattedEndDate}
              </Text>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.container}>
              <View style={styles.label}>
                <Text style={styles.text}>Opening Stock:</Text>
              </View>
              <View>
                <Text style={{ color: "grey" }}>|</Text>
              </View>
              <View style={styles.data}>
                <Text style={styles.text}>
                  {tradingList.Trading?.OpeningStock}
                </Text>
              </View>
            </View>

            <View style={styles.container}>
              <View style={styles.label}>
                <Text style={styles.text}>Closing Stock:</Text>
              </View>
              <View>
                <Text style={{ color: "grey" }}>|</Text>
              </View>
              <View style={styles.data}>
                <Text style={styles.text}>
                  {tradingList.Trading?.ClosingStock}
                </Text>
              </View>
            </View>

            <View style={styles.container}>
              <View style={styles.label}>
                <Text style={styles.text}>Total Purchase:</Text>
              </View>
              <View>
                <Text style={{ color: "grey" }}>|</Text>
              </View>
              <View style={styles.data}>
                <Text style={styles.text}>
                  {tradingList.Trading?.TotalPurchase}
                </Text>
              </View>
            </View>

            <View style={styles.container}>
              <View style={styles.label}>
                <Text style={styles.text}>Total Sale:</Text>
              </View>
              <View>
                <Text style={{ color: "grey" }}>|</Text>
              </View>
              <View style={styles.data}>
                <Text style={styles.text}>
                  {tradingList.Trading?.TotalSales}
                </Text>
              </View>
            </View>

            <View style={styles.container}>
              <View style={styles.label}>
                <Text style={styles.text}>Gross Profit:</Text>
              </View>
              <View>
                <Text style={{ color: "grey" }}>|</Text>
              </View>
              <View style={styles.data}>
                <Text style={styles.text}>{tradingList.GrossProfit}</Text>
              </View>
            </View>

            <View style={styles.container}>
              <View style={styles.label}>
                <Text style={styles.text}>Loss Suffered:</Text>
              </View>
              <View>
                <Text style={{ color: "grey" }}>|</Text>
              </View>
              <View style={styles.data}>
                <Text style={styles.text}>
                  {tradingList.Trading?.LossSuffered}
                </Text>
              </View>
            </View>

            <View style={styles.container}>
              <View style={styles.label}>
                <Text style={styles.text}>Total Amount:</Text>
              </View>
              <View>
                <Text style={{ color: "grey" }}>|</Text>
              </View>
              <View style={styles.data}>
                <Text style={styles.text}>{tradingList.TotalAmountLeft}</Text>
              </View>
            </View>

            <View style={styles.container}>
              <View style={styles.label}>
                <Text style={styles.text}>Total Amount:</Text>
              </View>
              <View>
                <Text style={{ color: "grey" }}>|</Text>
              </View>
              <View style={styles.data}>
                <Text style={styles.text}>{tradingList.TotalAmountRight}</Text>
              </View>
            </View>
          </View>
          <View></View>
          <View
            style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintTradingReport;
