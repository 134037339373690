import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import config from "../../config";
import LoginChecked from "../Shared/LoginChecked";
import { logos } from "../Shared/Logos";
import {
  Space,
  Card,
  Form,
  Select,
  Spin,
  DatePicker,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Tooltip,
  Button,
  Input,
  message,
} from "antd";
import { NavLink } from "react-router-dom";
import {
  CreditCardOutlined,
  DeleteOutlined,
  EditOutlined,
  ProfileOutlined,
  SearchOutlined,
  PlusOutlined,
  PrinterOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import AccountTypesDropdown from "../Shared/AccountTypesDropdown";
import BoxCard from "../Shared/BoxCard";

function ManageAccounts() {
  const { RangePicker } = DatePicker;
  const [StartDate, SetStartDate] = useState([]);
  const [EndDate, SetEndDate] = useState([]);

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [listofAccounts, SetlistofAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [orderby, SetOrderBy] = useState("1");
  const [AccountTypeID, SetAccountTypeID] = useState(0);
  const [BalanceType, SetBalanceType] = useState("Balance Type");
  const [Dated, setDated] = useState(null);
  const [searchedName, setSearchedName] = useState("");
  const [DebitSum, SetDebitSum] = useState("");
  const [CreditSum, SetCreditSum] = useState("");
  const [Name, setName] = useState([]);

  const accountTypes = AccountTypesDropdown();

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    document.title = "Manage Account";

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      OrderBy: orderby,
      PageNo: pageNo,
      PageSize: pageSize,
      BalanceType: BalanceType,
      Account_Type_ID: AccountTypeID,
      Dated: Dated,
      Name: searchedName,
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccounts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    // console.log("🚀 ~ useEffect testttttttesttttt~ api_config.data:", api_config.data)
    //console.log(data)
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofAccounts(response.data.listofAccounts);
          SetDebitSum(response.data.DebitSum);
          SetCreditSum(response.data.CreditSum);
          setTotalRecords(response.data.totalRecords);
          setName(response.data.listofAccounts?.Name);
          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        message.error("Network Error");
        console.log(error);
      });
  }, [
    AccountTypeID,
    BalanceType,
    orderby,
    Dated,
    pageNo,
    pageSize,
    searchedName,
  ]);

  const handleSearchByName = (e) => {
    setSearchedName(e.target.value);
  };

  const columns = [
    {
      title: "SR",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1 + pageSize * (pageNo - 1),
    },
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
      sorter: true,
    },
    {
      title: "Mobile",
      dataIndex: "Mobile_No",
      key: "Mobile_No",
    },
    {
      title: "Account Type",
      dataIndex: "Account_Type_BE", // Use the path to the nested object
      key: "Account_Type_BE",
      render: (accountTypeBE) => {
        // Render the 'Name' property of the 'Account_Type_BE' object
        return accountTypeBE ? accountTypeBE.Name : "";
      },
    },

    {
      title: "Current Balance ",
      dataIndex: "Balance",
      key: "balance",
      sorter: true,
      align: "right",
    },
    {
      title: "Type",
      dataIndex: "BalanceType",
      key: "type",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <div className="account-actions">
            <Space size="middle">
              <div>
                <Tooltip title="Edit Account">
                  <NavLink to={`/accounts/edit-account/${record.ID}`}>
                    <EditOutlined />
                  </NavLink>
                </Tooltip>
              </div>
              <div className={`${record.Is_Default ? "hidden" : ""}`}>
                <Tooltip title="Delete">
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={confirm}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Tooltip>
              </div>
              <div className={`${record.Is_Default ? "hidden" : ""}`}>
                <Tooltip title="Customer Rates">
                  <NavLink
                    to={`/accounts/custom-rates/?ID=${record.ID}&Name=${record.Name}`}
                  >
                    <CreditCardOutlined />
                  </NavLink>
                </Tooltip>
              </div>
              <div>
                <Tooltip title="View Ledger">
                  <NavLink
                    to={`/accounts/account-ledger/?account_id=${record.ID}`}
                  >
                    <ProfileOutlined />
                  </NavLink>
                </Tooltip>
              </div>
            </Space>
          </div>
        </>
      ),
    },
  ];

  const onPageChange = (page, pageSize) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1);
    setPageSize(size);
  };

  const confirm = (e) => {
    // console.log(e);
    message.success("Data deleted successfully");
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    // Make API request with sorting parameters
    const sortField = sorter.field;
    const sortOrder = sorter.order === "descend" ? "desc" : "asc";

    if (sortField == "Name") {
      if (sortOrder == "asc") {
        SetOrderBy(1);
      } else {
        SetOrderBy(2);
      }
    } else if (sortField == "Balance") {
      if (sortOrder == "asc") {
        SetOrderBy(3);
      } else {
        SetOrderBy(4);
      }
    }

    console.log("name order", orderby);
    console.log("balance order", orderby);

    console.log(sortField);
    console.log(sortOrder);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              {/* <NavLink to="/company/manage">
                <ChevronLeftIcon />
              </NavLink> */}
              Manage Accounts
            </h3>

            <div className="header-actions">
              <NavLink
                to={`/accounts/print-account-list/?OrderBy=${orderby}&PageNo=${currentPage}&PageSize=${pageSize}&BalanceType=${BalanceType}&Account_Type_ID=${AccountTypeID}&Dated=${Dated}&Name=${searchedName}`}
                target="blank"
              >
                <Button
                  type="dashed"
                  shape="round"
                  size="large"
                  icon={<PrinterOutlined />}
                >
                  Download / Print
                </Button>
              </NavLink>

              <NavLink to="/accounts/add-account">
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  icon={<PlusOutlined style={{ color: "white" }} />}
                >
                  Add Account
                </Button>
              </NavLink>
            </div>
          </div>

          <div className="card-total-detal">
            <Row gutter={[16,16]} style={{ marginBottom: "16px" }}>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Total Debit"
                  amount={DebitSum}
                  prefix="Rs."
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="#6FB2FF"
                      class="size-6"
                      viewBox="0 0 30 30"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
              <Col md={6}>
                <BoxCard
                loading={loading}
                  title="Total Credit"
                  amount={CreditSum}
                  prefix="Rs."
                  icon={
                    <svg
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#6FB2FF"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
            </Row>
          </div>

          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <Input
                  placeholder="Search By Name"
                  value={searchedName}
                  onChange={handleSearchByName}
                />
              </Form.Item>

              <Form.Item>
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: "200px",
                  }}
                  placeholder="Account Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => SetAccountTypeID(value)}
                  options={accountTypes}
                />
              </Form.Item>

              <Form.Item>
                <Select
                  showSearch
                  style={{
                    width: 150,
                  }}
                  placeholder="Balance Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  onChange={(value) => SetBalanceType(value)}
                  value={BalanceType}
                  allowClear
                  options={[
                    {
                      value: "Debit",
                      label: "Debit",
                    },
                    {
                      value: "Credit",
                      label: "Credit",
                    },
                  ]}
                />
              </Form.Item>

              <Form.Item>
                <DatePicker
                  placeholder="Balance on Date"
                  onChange={(date, dateString) => setDated(dateString)}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Form>
          </div>
          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            dataSource={listofAccounts}
            pagination={false}
            onChange={handleTableChange}
            bordered
          />

          <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange} // Handle page size change
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageAccounts;
