import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import config from "../../config";
import PrintHeaderAccount from "../Reoprts/Prints/PrintHeaderAccount";
import { Spin, Space } from "antd";

function PrintProductLedger() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [ListofProductLedger, SetListofProductLedger] = useState([]);
  const [Product,SetProduct] = useState("")
  const urlParams = new URLSearchParams(window.location.search);
  const product_id = urlParams.get("product_id");
  const StartDate = urlParams.get('StartDate');
  const EndDate = urlParams.get('EndDate');
  const account_name = urlParams.get("name");

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      PageNo: 0,
      PageSize:2000,
      ID: product_id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProductLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetListofProductLedger(response.data.listofProductLedger);

          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    document.title = "Product Ledger";
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      ID: product_id,
    };

    console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProductByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log("Name",response.data);
        if (response.data.status_code == 1) {
          SetProduct(response.data.Product.Name);
        } else {
        }
        // console.log("🚀 ~ response:", response)
        //   setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

 

  // Create styles
  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
      //flexGrow: 1
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    table: {
      display: "table",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    th: {
      flex: 1,
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    thValue: {
      fontSize: "8pt",
      fontWeight: "bold",
    },

    row: {
      flexDirection: "row",
    },
    td: {
      flex: 1,
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    tdValue: {
      fontSize: "8pt",
    },
  });

  const generateData = () => {
    if (ListofProductLedger?.length > 0) {
      return ListofProductLedger.map((item, index) => {
        return (
          <View key={index} style={styles.row}>
            <View style={[styles.td, { flexGrow: 0, paddingRight: 20 }]}>
              <Text style={styles.tdValue}>{index + 1}</Text>
            </View>
            <View style={styles.td}>
              <Text style={styles.tdValue}>
                {moment(item.Transaction_Date).format(config.date_format)}
              </Text>
            </View>
            <View style={styles.td}>
              <Text style={styles.tdValue}>{item.Description}</Text>
            </View>
            <View style={styles.td}>
              <Text style={styles.tdValue}>{item.Debit}</Text>
            </View>
            <View style={styles.td}>
              <Text style={styles.tdValue}>{item.Credit}</Text>
            </View>
            <View style={styles.td}>
              <Text style={styles.tdValue}>{item.Balance}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <PrintHeaderAccount />

        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={{ fontSize: "12pt" }}>
              Ledger details for {Product}
            </Text>
            <Text style={{ fontSize: "10pt" }}>
              Start Date : {StartDate} End Date: {EndDate}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <View style={[styles.th, { flexGrow: 0, paddingRight: 20 }]}>
                <Text style={styles.thValue}>Sr. #</Text>
              </View>
              <View style={styles.th}>
                <Text style={styles.thValue}>Dated</Text>
              </View>
              <View style={styles.th}>
                <Text style={styles.thValue}>Description</Text>
              </View>
              <View style={styles.th}>
                <Text style={styles.thValue}>In</Text>
              </View>
              <View style={styles.th}>
                <Text style={styles.thValue}>Out</Text>
              </View>
              <View style={styles.th}>
                <Text style={styles.thValue}>Balance</Text>
              </View>
            </View>
            {generateData()}
          </View>
          <View
            style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      {loading ? (
        <div
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
      ) : (
        <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        </div>
      )}
    </>
  );
}

export default PrintProductLedger;
