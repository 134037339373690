import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Statistic,
  Card,
  Space,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  DatePicker,
  Form,
  Select,
  message,
} from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import SecurityMenu from "./SecurityMenu";
import moment from "moment";
import { NavLink } from "react-router-dom";
import Header from "../Layout/Header";

import axios from "axios";
import config from "../../config";
import LoginChecked from "../Shared/LoginChecked";

function LoginLogs() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    document.title = "Login Logs";
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formattedDate = moment(currentTime).format("DD MMM YYYY, h:mm:ss a");

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [ListofLoginLog, SetlistofLoginLog] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetLoginLogs",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofLoginLog(response.data.listofLogs);
          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const columns = [
    {
      title: "Sr.#",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Dated",
      dataIndex: "Created_Date",
      key: "Created_Date",
      render: (_, record) => {
        return dayjs(record.Created_Date).format("DD MMM YYYY hh:mm A");
      }
    },
    {
      title: "User Name",
      dataIndex: "User_Name",
      key: "User_Name",
    },
    {
      title: "Success",
      dataIndex: "Is_Success",
      key: "Is_Success",
      render: (isSuccess) =>
        isSuccess ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red" }} />
        ),
    },
    {
      title: "IP Address",
      dataIndex: "IP_Address",
      key: "Address",
    },

    {
      title: "User Type",
      dataIndex: "User_Type",
      key: "UserType",
    },
    {
      title: "Source",
      dataIndex: "Login_Source",
      key: "Source",
    },
  ];

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Login Logs</h3>

            <div className="header-actions">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div style={{ marginRight: "30px", marginBottom: "1rem" }}>
                  Current Server Time is:
                </div>
                <div
                  style={{
                    color: "#3f51b5",
                    fontSize: "15px",
                    fontWeight: "700",
                    marginBottom: "1rem",
                  }}
                >
                  {formattedDate}
                </div>
              </div>
            </div>
          </div>

          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            dataSource={ListofLoginLog}
            // onChange={handleTableChange}
            // pagination={false}
          />
        </div>
      </div>
    </>
  );
}

export default LoginLogs;
