import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";

import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import dayjs from "dayjs";
import { Select, Skeleton } from "antd";

// Register necessary components to ChartJS
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function SaleChart(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const [StartDate, setStartDate] = useState(
    dayjs().subtract(15, "day").format("YYYY-MM-DD")
  );
  const [EndDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));

  const [Value, setValue] = useState(15);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Sale/GetSalesGraph",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    //console.log(api_config);

    axios(api_config)
      .then(function (response) {
        // console.log(response.data, "chart---------");
        if (response.data.status_code == 1) {



          // Handle success
          const data = response.data.SalesGraph;
          const labels = data.map((item) =>
            dayjs(item.Period).format("DD-MM-YYYY")
          );
          const salesData = data.map((item) => item.TotalSales);

          setChartData({
            labels,
            datasets: [
              {
                label: "Total Sales",
                data: salesData,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                borderWidth: 1,
                borderRadius: 50,
                borderSkipped: false,
              },
            ],
          });

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        // console.log(error);
      });
  }, [StartDate, EndDate]);

  const options = {
    responsive: true,

  };

  const handleDateChange = (value) => {
    setValue(value);

    setEndDate(dayjs().format("YYYY-MM-DD"));
    setStartDate(dayjs().subtract(value, "day").format("YYYY-MM-DD"));
  };

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <Select
            style={{ width: 200 }}
            placeholder="Select duration"
            onChange={handleDateChange}
            value={Value}
            options={[
              {
                label: "Last 15 Days",
                value: 15,
              },
              {
                label: "Last 30 Days",
                value: 30,
              },
            ]}
          />
          <Bar

            data={chartData}
            options={options}
          />
        </>
      )}
    </>
  );
}

export default SaleChart;
