import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import { Spin } from "antd";
import axios from "axios";
import config from "../../../config";
import PrintHeaderAccount from "../../Accounts/PrintHeaderAccount";
import { useParams } from "react-router-dom";

const PrintProductList = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const Dated = urlParams.get("Created_Date");

  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  const [ListofProducts, SetListofProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Created_Date: Dated,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProducts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetListofProducts(response.data.ListofProducts);
          setData(response.data);
          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    table: {
      display: "flex",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    th: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    thValue: {
      fontSize: "8pt",
      fontWeight: "bold",
    },

    row: {
      flexDirection: "row",
    },
    td: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    tdValue: {
      fontSize: "8pt",
    },
  });

  const generateData = () => {
    if (ListofProducts && ListofProducts.length > 0) {
      return ListofProducts.map((item, index) => (
        <View key={index} style={styles.row}>
          <View style={[styles.td, { width: 40 }]}>
            <Text style={styles.tdValue}>{index + 1}</Text>
          </View>

          <View style={[styles.td, { width: 50 }]}>
            <Text style={styles.tdValue}>{item.ID}</Text>
          </View>
          <View style={[styles.td, { width: 120 }]}>
            <Text style={styles.tdValue}>{item.Name}</Text>
          </View>
          <View style={[styles.td, { width: 50 }]}>
            <Text style={styles.tdValue}>{item.ProductCode}</Text>
          </View>
          <View style={[styles.td, { width: 50, textAlign: "right" }]}>
            <Text style={styles.tdValue}>{item.Sale_Price}</Text>
          </View>
          <View style={[styles.td, { width: 100, textAlign: "right" }]}>
            <Text style={styles.tdValue}>{item.Last_Purchase_Price}</Text>
          </View>
          <View style={[styles.td, { width: 80, textAlign: "right" }]}>
            <Text style={styles.tdValue}>{item.Balance}</Text>
          </View>
          <View style={[styles.td, { width: 80, textAlign: "right" }]}>
            <Text style={styles.tdValue}>{(item.Balance * item.Last_Purchase_Price).toFixed(2)}</Text>
          </View>
        </View>
      ));
    } else {
      return null;
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <PrintHeaderAccount />
        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={{ fontSize: "12pt" }}>Product Detail List</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 40 }]}>
                <Text style={styles.thValue}>Sr No.</Text>
              </View>

              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>ID</Text>
              </View>
              <View style={[styles.th, { width: 120 }]}>
                <Text style={styles.thValue}>Name</Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>Code</Text>
              </View>
              <View style={[styles.th, { width: 50, textAlign: "right" }]}>
                <Text style={styles.thValue}>Sale Price</Text>
              </View>
              <View style={[styles.th, { width: 100, textAlign: "right" }]}>
                <Text style={styles.thValue}>Last Purchase price</Text>
              </View>
              <View style={[styles.th, { width: 80, textAlign: "right" }]}>
                <Text style={styles.thValue}>Current Qty</Text>
              </View>
              <View style={[styles.th, { width: 80, textAlign: "right" }]}>
                <Text style={styles.thValue}>Stock Value</Text>
              </View>
            </View>
            {generateData()}
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: "100%", textAlign: "right" }]}>
                <Text style={[styles.thValue, { fontSize: "10pt" }]}>
                  Total Stock Value: {data.TotalStockValue}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
};

export default PrintProductList;
