import React, { useState, useEffect } from "react";
import config from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
function LoginChecked(props) {
  let navigate = useNavigate();
  const [AccessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
  const [UserID, setUserID] = useState(localStorage.getItem("ID"));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (AccessKey == null) {
      navigate("/login");
    } else {
      const dashboardParms = {
        AccessKey: AccessKey,
        UserID: UserID,
      };

      var api_config = {
        method: "post",
        url: config.base_url + "Members/ValidateAccessKey",
        headers: {
          "Content-Type": "application/json",
        },
        data: dashboardParms,
      };

      axios(api_config)
        .then(function (response) {
          // console.log("resp", response.data);
          if (response.data.status_code == 0) {
            localStorage.clear();
            navigate("/login");
          }

          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, []);

  return <></>;
}

export default LoginChecked;
