import React, { useState, useEffect } from "react";
import Header from "./PrintHeaderAccount";
import axios from "axios";
import config from "../../../config";
import dayjs from "dayjs";
import moment from "moment";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { Spin } from "antd";

function PrintExpensReport() {
  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  const urlParams = new URLSearchParams(window.location.search);
  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");
  const ReportType = urlParams.get("ReportType");
  const [DateWiseReport, setDateWiseReport] = useState([]);
  const [AccountWise, setAccountWise] = useState([]);

  const [ExpensReport, setExpensReport] = useState([]);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      ReportType: ReportType,
    };

    console.log("this is data", data);
    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetExpenseReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setExpensReport(response.data);
          setAccountWise(response.data.ListofVouchers);
          setDateWiseReport(response.data.ListofExpenseVouchers);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
      //flexGrow: 1
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    table: {
      display: "flex",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    th: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    thValue: {
      fontSize: "8pt",
      fontWeight: "bold",
    },

    row: {
      flexDirection: "row",
    },
    td: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    tdValue: {
      fontSize: "8pt",
      color: "black",
    },
  });

  const generateDateWiseData = () => {
    if (DateWiseReport.length > 0) {
      return DateWiseReport.map((item, index) => {
        return (
          <View key={index} style={styles.row}>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[styles.td, { width: 100 }]}>
              <Text style={styles.tdValue}>
                {dayjs(item.Dated).format(config.date_format)}
              </Text>
            </View>
            <View style={[styles.td, { width: 410, textAlign: "right" }]}>
              <Text style={styles.tdValue}>{item.Amount}</Text>
            </View>
          </View>
        );
      });
    }
  };
  const generateAccountWiseData = () => {
    if (AccountWise.length > 0) {
      return AccountWise.map((item, index) => {
        return (
          <View key={index} style={styles.row}>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{item.ID}</Text>
            </View>
            <View style={[styles.td, { width: 80 }]}>
              <Text style={styles.tdValue}>
                {moment(item.Created_Date).format("DD MMM YYYY")}
              </Text>
            </View>
            <View style={[styles.td, { width: 70 }]}>
              <Text style={styles.tdValue}>{item.Debit_Account?.Name}</Text>
            </View>
            <View style={[styles.td, { width: 80 }]}>
              <Text style={styles.tdValue}>{item.Credit_Account?.Name}</Text>
            </View>
            <View style={[styles.td, { width: 200 }]}>
              <Text style={styles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[styles.td, { width: 80, textAlign: "right" }]}>
              <Text style={styles.tdValue}>{item.Amount}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={{ fontSize: "12pt" }}>Expense Report</Text>
          </View>
          <View style={styles.table}>
            {ReportType === "Account Wise" ? (
              <>
                <View style={styles.tableHead}>
                  <View style={[styles.th, { width: 50 }]}>
                    <Text style={styles.thValue}>ID</Text>
                  </View>
                  <View style={[styles.th, { width: 80 }]}>
                    <Text style={styles.thValue}>Date</Text>
                  </View>
                  <View style={[styles.th, { width: 70 }]}>
                    <Text style={styles.thValue}>Debit Account</Text>
                  </View>
                  <View style={[styles.th, { width: 80 }]}>
                    <Text style={styles.thValue}>Credit Account</Text>
                  </View>
                  <View style={[styles.th, { width: 200 }]}>
                    <Text style={styles.thValue}>Description</Text>
                  </View>
                  <View style={[styles.th, { width: 80, textAlign: "right" }]}>
                    <Text style={styles.thValue}>Amount</Text>
                  </View>
                </View>
                {generateAccountWiseData()}

                <View style={styles.tableHead}>
                  <View style={[styles.th, { width: 480, textAlign: "right" }]}>
                    <Text style={styles.thValue}>Total Expense</Text>
                  </View>
                  <View style={[styles.th, { width: 80, textAlign: "right" }]}>
                    <Text style={styles.thValue}>
                      {ExpensReport.TotalExpenses}
                    </Text>
                  </View>
                </View>
              </>
            ) : ReportType === "Date Wise" ? (
              <>
                <View style={styles.tableHead}>
                  <View style={[styles.th, { width: 50 }]}>
                    <Text style={styles.thValue}>SR.</Text>
                  </View>
                  <View style={[styles.th, { width: 100 }]}>
                    <Text style={styles.thValue}>Date</Text>
                  </View>
                  <View style={[styles.th, { width: 410, textAlign: "right" }]}>
                    <Text style={styles.thValue}>Amount</Text>
                  </View>
                </View>

                {generateDateWiseData()}

                <View style={styles.tableHead}>
                  <View style={[styles.th, { width: 480, textAlign: "right" }]}>
                    <Text style={styles.thValue}>Total Expense</Text>
                  </View>
                  <View style={[styles.th, { width: 80, textAlign: "right" }]}>
                    <Text style={styles.thValue}>
                      {ExpensReport.TotalExpenses}
                    </Text>
                  </View>
                </View>
              </>
            ) : null}

            <View
              style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }}
              fixed
            >
              <Text
                style={{ fontSize: "8pt" }}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} / ${totalPages}`
                }
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintExpensReport;
