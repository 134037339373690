import React, { useState, useEffect } from "react";

import { NavLink, Link } from "react-router-dom";
import axios from "axios";

import logo from "../../assets/images/header-img.png";

import { useNavigate } from "react-router-dom";
import LoginChecked from "../Shared/LoginChecked";
import moment from "moment";

import config from "../../config";
import Header from "../Layout/Header";
// import DashboardMenu from './DashboardMenu';
import {
  Avatar,
  Breadcrumb,
  Card,
  Col,
  List,
  Row,
  Space,
  Spin,
  Statistic,
  Tooltip,
  Button,
  Modal,
  Form,
  Input,
  message,
  Checkbox,
  Table,
} from "antd";
import {
  EditOutlined,
  CreditCardOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import StatBox from "./StatBox";
import SaleChart from "./SaleChart";
import dayjs from "dayjs";

function Analytics() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [NoOfAccounts, SetNoOfAccounts] = useState("");
  const [TotalSales, SetTotalSales] = useState("");
  const [TotalExpenses, SetTotalExpenses] = useState("");
  const [TotalPurchases, SetTotalPurchases] = useState("");
  const Email_Address = localStorage.getItem("Email_Address");
  const Mobile_No = localStorage.getItem("Mobile_No");
  const Full_Name = localStorage.getItem("Full_Name");
  const [DebitAccountID, setdebitAccountID] = useState();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [selectedProduct, setSelectedProduct] = useState({});
  const [loadingCahsAccountID, setloadingCahsAccountID] = useState(false);
  const [loadingProductId, setLoadingProductId] = useState(false);
  const [ListCashAccounts, setListCashAccounts] = useState([]);
  const [LoadingProduct, setLoadingProduct] = useState(false);
  const [cashAccountId, setCashAccountId] = useState(0);
  const [productID, setProductID] = useState(0);
  const [listOfSaleProduct, setListOfSaleProduct] = useState([]);
  const [Dated, setDated] = useState(moment().format("YYYY-MM-DD"));
  const [dieselID, setDieselID] = useState(0);
  const [Button_Lodding, setButton_Lodding] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [ProductUpdated, setProductUpdated] = useState(false);
  const [fillPercentage, setFillPercentage] = useState(10);
  const [ListofLoginLog, SetlistofLoginLog] = useState([]);
  const [ListOfTanks, setListOfTanks] = useState([]);
  const [Summaryreport,setSummaryreport] = useState([]);
  const [EndDate, SetEndDate] = useState(dayjs());
  const [StartDate, SetStartDate] = useState(dayjs().subtract(7, "day"));

  useEffect(() => {
    document.title = "Dashboard";

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/GetDashboardData",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // console.log(api_config);

    axios(api_config)
      .then(function (response) {
        // console.log("response.data", response.data);
        if (response.data.status_code == 1) {
          // Handle success
          console.log(response.data);
          setData(response.data);
          SetNoOfAccounts(response.data.NoOfAccounts);
          SetTotalSales(response.data.TotalSales);
          SetTotalExpenses(response.data.TotalExpenses);
          SetTotalPurchases(response.data.TotalPurchases);
          setListOfTanks(response.data.ListofTanks);
          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setloadingCahsAccountID(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Account_Type_ID: 0,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountsByType",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log("get accounts by type cash", response.data);
        if (response.data.status_code == 1) {
          // Handle success
          setListCashAccounts(response.data.listofAccounts);
          setloadingCahsAccountID(false);
        } else {
          setloadingCahsAccountID(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!loadingCahsAccountID) {
      const list = ListCashAccounts.filter(
        (item) =>
          item.Account_Type_ID === 1 || item.Account_Type_BE?.Name === "Cash"
      );

      //console.log("lists", list);
      if (list.length > 0) {
        setCashAccountId(list[0].ID);
      }
    }
  }, [ListCashAccounts]);

  useEffect(() => {
    const dashboardParms = {
      AccessKey: AccessKey,
      UserID: UserID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Members/ValidateAccessKey",
      headers: {
        "Content-Type": "application/json",
      },
      data: dashboardParms,
    };

    axios(api_config)
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoadingProductId(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Created_Date: Dated,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProducts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          setListOfSaleProduct(response.data.ListofProducts);
          setLoadingProductId(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!loadingProductId) {
      const petrolProduct = listOfSaleProduct.find(
        (item) => item.Name === "Petrol"
      );
      const dieselProduct = listOfSaleProduct.find(
        (item) => item.Name === "Hi Speed Diesel"
      );

      if (petrolProduct) {
        setProductID(petrolProduct.ID);
      }
      if (dieselProduct) {
        setDieselID(dieselProduct.ID);
      }
    }
  }, [listOfSaleProduct]);

  const Update_Product_Price = (formData) => {
    setButton_Lodding(true);
    setLoadingProduct(true);
    // setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: selectedProduct.ID,
      UpdateAllRates: checkboxChecked,
      ...formData,
    };

    console.log("data", data);

    const api_config = {
      method: "post",
      url: config.base_url + "Product/UpdateRate",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setProductUpdated(true);
          setButton_Lodding(false);
          setLoadingProduct(false);
          // setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          // setConfirmLoading(true);
          setTimeout(() => {
            setOpen(false);
            // setConfirmLoading(false);
          }, 2000);
        } else {
          // setLoading(false);
          setLoadingProduct(false);
          setButton_Lodding(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        // setLoading(false);
        setLoadingProduct(false);
        setButton_Lodding(false);
      });
  };

  const handleCheckboxChange = (e) => {
    setCheckboxChecked(e.target.checked);
  };

  const showModal = (item) => {
    console.log(item);
    const editProduct = {
      ID: item.ID,
      Name: item.Name,
      Last_Purchase_Price: item.Last_Purchase_Price,
    };

    setSelectedProduct(editProduct);
    setOpen(true);
  };

  const handleCancel = () => {
    setSelectedProduct(null);
    setOpen(false);
  };

  useEffect(() => {
    document.title = "Datewise Summary";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };
    console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "Report/DateWiseSummaryReport ",
      header: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setSummaryreport(response.data.ListofSummary);
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const DateWiseSummarycolumns = [
    {
      title: "Sr.#",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "Dated",
      render: (_, record) => dayjs(record.Dated).format(config.date_format),
    },
    {
      title: "Format 1",
      key: "action",
      render:(_,record)=>
        <Tooltip title="Print 1">
            <NavLink
              to={`/report-datewiseSummary/print-list/?Dated=${dayjs(
                record.Dated
              ).format("YYYY-MM-DD")}`}
              target="_blank"
            >
             Download 
             {/* <PrinterOutlined />  */}
            </NavLink>
          </Tooltip>
    },
    {
      title: "Format 2",
      key: "action",
      render:(_,record)=>
        <Tooltip title="Print 2">
            <NavLink to={`/report-datewiseSummary2/print-list/?Dated=${dayjs(
                record.Dated
              ).format("YYYY-MM-DD")}`} target="_blank">
             Download 
             {/* <PrinterOutlined /> */}
            </NavLink>
          </Tooltip>
    }
  ];



  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetLoginLogs",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofLoginLog(response.data.listofLogs);
          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const colorMapping = {
    PET: "rgba(0, 143, 57, 1)",
    SPET: "rgba(255, 0, 0, 1)",
    HPET: "rgba(0, 0, 255, 1)",
    HSD: "rgba(216, 161, 96, 1)",
  };

  const columns = [
    {
      title: "Sr.#",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Dated",
      dataIndex: "Created_Date",
      key: "Created_Date",
      render: (_, record) => dayjs(record.Created_Date).format("DD-MM-YYYY"),
    },
    {
      title: "User Name",
      dataIndex: "User_Name",
      key: "User_Name",
    },
    {
      title: "User Type",
      dataIndex: "User_Type",
      key: "UserType",
    },
  ];

  return (
    <>
      <LoginChecked />

      <Modal
        title={`Update Price Of ${selectedProduct ? selectedProduct.Name : ""}`}
        onCancel={handleCancel}
        footer={null}
        open={open}
        style={{ maxWidth: "350px" }}
      >
        <Form
          title="Update Products Rates"
          name="basic"
          layout="vertical"
          onFinish={Update_Product_Price}
          autoComplete="off"
          initialValues={selectedProduct}
          fields={[
            {
              name: "ID",
              value: selectedProduct?.ID,
            },
            {
              name: "Name",
              value: selectedProduct?.Name,
            },
            {
              name: "Amount",
              value: selectedProduct?.Last_Purchase_Price,
            },
          ]}
        >
          {selectedProduct ? (
            <div style={{ paddingBottom: "10px" }}>
              Current Price: <b>{selectedProduct.Last_Purchase_Price}</b>
            </div>
          ) : (
            ""
          )}
          <Row>
            <Col span={24}>
              <Form.Item name="SalePrice" label="New Price">
                <Input />
              </Form.Item>
            </Col>

            <Col>
              <Checkbox onChange={handleCheckboxChange}>
                Apply For All Customers Sales
              </Checkbox>
            </Col>
            <Col span={24}>
              <div className="text-right">
                <Space wrap>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    loading={Button_Lodding}
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      {contextHolder}

      <div className="right-side-contents">
        {loading ? (
          <div className="spin-center">
            <Space size="large">
              <Spin size="large" />
            </Space>
          </div>
        ) : (
          <div className="page-content">
            <div className="page-header">
              <h3 className="page-title">Dashboard</h3>
            </div>

            <div className="dash-card" style={{ marginBottom: 24 }}>
              <h3>Sales Overview</h3>

              <Row gutter={24}>
                <Col xs={24} md={18}>
                  <SaleChart />
                </Col>
                <Col xs={24} md={6}>
                  <NavLink
                    to={`/reports/product-ledger/?product_id=${productID}&Name=${"Petrol"}`}
                  >
                    <div
                      className="product-sale-box"
                      style={{ cursor: "pointer" }}
                    >
                      <p>Petrol Sale</p>
                      <h4>{data.PetrolSaleQuantity} Ltr.</h4>
                    </div>
                  </NavLink>

                  <NavLink
                    to={`/reports/product-ledger/?product_id=${dieselID}&Name=${"Diesel"}`}
                  >
                    <div
                      className="product-sale-box"
                      style={{ cursor: "pointer" }}
                    >
                      <p>Diesel Sale</p>
                      <h4>{data.DieselSaleQuantity} Ltr.</h4>
                    </div>
                  </NavLink>
                </Col>
              </Row>
            </div>
            <Row gutter={[20, 30]}>
              <Col xs={24} md={6}>
                <StatBox
                  label="Total Purchase"
                  value={TotalPurchases}
                  linktext="View Purchases"
                  link="/purchase/manage-purchase"
                />
              </Col>
              <Col xs={24} md={6}>
                <StatBox
                  label="Total Sale"
                  value={TotalSales}
                  linktext="View Sales"
                  link="/sales/view-sales"
                />
              </Col>
              <Col xs={24} md={6}>
                <StatBox
                  label="Total Expense"
                  value={TotalExpenses}
                  linktext="View Expenses"
                  link="/vouchers/manage-vouchers"
                />
              </Col>
              <Col xs={24} md={6}>
                {!loadingCahsAccountID && (
                  <StatBox
                    label="Cash in Hand"
                    value={data.CashInHand}
                    linktext="View Ledger"
                    link={`/accounts/account-ledger/?account_id=${cashAccountId}&Name=${"cash"}`}
                  />
                )}
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} md={10}>
                <div className="dash-card no-shadow" style={{ marginTop: 24 }}>
                  <h3>Stock Details</h3>
                  <ul className="dashboard-stock-list">
                    {data.ListofStockDetails &&
                      data.ListofStockDetails.map((item, index) => (
                        <li key={index}>
                          <div className="p-name">
                            <div className="code">{item.ProductCode}</div>
                            <Tooltip title="View Stock Detail">
                              <NavLink
                                style={{ textDecoration: "none" }}
                                to={`/reports/product-ledger/?product_id=${item.ID}`}
                              >
                                <div>
                                  <p>{item.Name}</p>
                                  <h4>
                                    {item.Balance}{" "}
                                    <span>{item.Measure_Unit_BE?.Name}</span>
                                  </h4>
                                </div>
                              </NavLink>
                            </Tooltip>
                          </div>

                          <div className="last-purchase">
                            <p>Last Purchase Price</p>

                            <h2>Rs. {item.Last_Purchase_Price}</h2>

                            <Space size={"middle"} style={{ marginTop: "5px" }}>
                              <NavLink to={`/products/edit-product/${item.ID}`}>
                                <Tooltip title="Edit Product">
                                  <EditOutlined style={{ color: "blue" }} />
                                </Tooltip>
                              </NavLink>
                              <Tooltip title="Edit Price ">
                                <CreditCardOutlined
                                  onClick={() => showModal(item)}
                                />
                              </Tooltip>
                            </Space>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <div
                  className="dash-card no-shadow"
                  style={{ marginTop: 24, border: "none", padding: "0px" }}
                >
                  <h3>Login Logs</h3>
                  <Table
                    size="small"
                    scroll={{
                      x: "100%",
                    }}
                    loading={loading}
                    columns={columns}
                    dataSource={ListofLoginLog}
                    pagination={false}
                  />
                </div>
              </Col>
              <Col md={14}>
                <div className="dash-card no-shadow" style={{ marginTop: 24 }}>
                  <NavLink to={"/dipsettings/manage-tanks"}>
                    <p style={{ textAlign: "end" }}>
                      <span
                        style={{
                          background: "rgb(229 248 255)",
                          padding: "5px",
                          borderRadius: "5px",
                        }}
                      >
                        Manage Tanks
                      </span>
                    </p>
                  </NavLink>
                  <div className="tank-wrapper">
                    {ListOfTanks.map((product) => {
                      // const fillPercentage =
                      //   (product.TankFullCapacity / product.UseableCapacity) *
                      //   100;

                      const backgroundColor =
                        colorMapping[product.ProductCode] ||
                        "rgba(200, 200, 200, 1)";

                      return (
                        <div key={product.ProductCode} style={{marginLeft:"10px"}}>
                          <h2
                            style={{
                              marginBottom: "10px",
                              textAlign: "center",
                            }}
                          >
                            {product.Name}
                          </h2>
                          <div className="tank">
                            <div
                              className="oil"
                              style={{
                                height: `${product.TankFullCapacity}%`,
                                backgroundColor: backgroundColor,
                              }}
                            >
                              <div className="wave"></div>
                              <div className="wave second-wave"></div>
                            </div>
                          </div>
                          <p>
                            <b>{product.Name}:</b>{" "}
                            <span>{product.TankFullCapacity.toFixed(2)}%</span>
                          </p>
                          <p>
                            <b>{product.Name}:</b>{" "}
                            <span>{product.Balance} Ltr</span>
                          </p>
                          <p>
                            <b>Tank Capacity:</b>{" "}
                            <span>{product.UseableCapacity}</span>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="dash-card no-shadow"
                  style={{ marginTop: 24, border: "none", padding: "0px" }}
                >
                  <h3>DateWise Summary</h3>
                  {/* <Table
                    size="small"
                    scroll={{
                      x: "100%",
                    }}
                    loading={loading}
                    columns={columns}
                    dataSource={ListofLoginLog}
                    pagination={false}
                  /> */}
                  <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={DateWiseSummarycolumns}
            dataSource={Summaryreport}
            
            pagination={false}
            bordered
          />
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
}

export default Analytics;
