import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from "../../../config";
import PrintHeaderAccount from "../../Accounts/PrintHeaderAccount";
import { Spin } from "antd";

const PrintVoucherList = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const voucher_id = urlParams.get("voucher_id");

  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  const [listofVouchers, SetlistofVouchers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: voucher_id,
    };
    console.log("🚀 ~ useEffect ~ data:", data);
    var api_config = {
      method: "post",
      url: config.base_url + "Voucher//GetVoucherByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log("response", response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofVouchers(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
      //flexGrow: 1
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    table: {
      display: "flex",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    th: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    thValue: {
      fontSize: "8pt",
      fontWeight: "bold",
    },

    row: {
      flexDirection: "row",
    },
    td: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    tdValue: {
      fontSize: "8pt",
      color: "black",
    },
  });

  console.log(listofVouchers);

  const generateDataDebitAccount = () => {
    return (
      <View style={styles.row}>
        <View style={[styles.td, { width: 100 }]}>
          <Text style={styles.tdValue}>
            {listofVouchers.DebitAccount?.Name}
          </Text>
        </View>
        <View style={[styles.td, { width: 80 }]}>
          <Text style={styles.tdValue}>
            {listofVouchers.DebitAccount?.BalanceType}
          </Text>
        </View>
        <View style={[styles.td, { width: 90 }]}>
          <Text style={styles.tdValue}>
            {listofVouchers.DebitAccount?.Email_Address}
          </Text>
        </View>
        <View style={[styles.td, { width: 90 }]}>
          <Text style={styles.tdValue}>
            {listofVouchers.DebitAccount?.Mobile_No}
          </Text>
        </View>
        <View style={[styles.td, { width: 100, textAlign: "right" }]}>
          <Text style={styles.tdValue}>{listofVouchers.Amount}</Text>
        </View>
        <View style={[styles.td, { width: 100 }]}>
          <Text style={styles.tdValue}>
            {listofVouchers.DebitAccount?.Balance}
          </Text>
        </View>
      </View>
    );
  };

  const generateDataCreditAccount = () => {
    return (
      <View style={styles.row}>
        <View style={[styles.td, { width: 100 }]}>
          <Text style={styles.tdValue}>
            {listofVouchers.CreditAccount?.Name}
          </Text>
        </View>
        <View style={[styles.td, { width: 80 }]}>
          <Text style={styles.tdValue}>
            {listofVouchers.CreditAccount?.BalanceType}
          </Text>
        </View>
        <View style={[styles.td, { width: 90 }]}>
          <Text style={styles.tdValue}>
            {listofVouchers.CreditAccount?.Email_Address}
          </Text>
        </View>
        <View style={[styles.td, { width: 90 }]}>
          <Text style={styles.tdValue}>
            {listofVouchers.CreditAccount?.Mobile_No}
          </Text>
        </View>
        <View style={[styles.td, { width: 100, textAlign: "right" }]}>
          <Text style={styles.tdValue}>{listofVouchers.Amount}</Text>
        </View>
        <View style={[styles.td, { width: 100 }]}>
          <Text style={styles.tdValue}>
            {listofVouchers.CreditAccount?.Balance}
          </Text>
        </View>
      </View>
    );
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <PrintHeaderAccount />
        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={{ fontSize: "12pt" }}>GENERAL VOUCHERS</Text>
          </View>
          <View style={styles.table}>
            <Text style={{ fontSize: "10pt", padding: "5px" }}>
              Debit Account Details:
            </Text>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 100 }]}>
                <Text style={styles.thValue}>Account Title</Text>
              </View>
              <View style={[styles.th, { width: 80 }]}>
                <Text style={styles.thValue}>Type</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Email Address</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Mobile NO</Text>
              </View>
              <View style={[styles.th, { width: 100, textAlign: "right" }]}>
                <Text style={styles.thValue}>Amount</Text>
              </View>
              <View
                style={[
                  styles.th,
                  {
                    width: 100,
                    display: "flex",
                    flexDirection: "column",
                  },
                ]}
              >
                <Text style={styles.thValue}>Current Balance</Text>
                <Text style={styles.thValue}>(After this Entry)</Text>
              </View>
            </View>

            {generateDataDebitAccount()}
          </View>

          <View style={[styles.table, { marginTop: "20px" }]}>
            <Text style={{ fontSize: "10pt", padding: "5px" }}>
              Credit Account Details:
            </Text>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 100 }]}>
                <Text style={styles.thValue}>Account Title</Text>
              </View>
              <View style={[styles.th, { width: 80 }]}>
                <Text style={styles.thValue}>Type</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Email Address</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Mobile NO</Text>
              </View>
              <View style={[styles.th, { width: 100, textAlign: "right" }]}>
                <Text style={styles.thValue}>Amount</Text>
              </View>
              <View style={[styles.th, { width: 100 }]}>
                <Text style={styles.thValue}>
                  Current Balance (After this Entry)
                </Text>
              </View>
            </View>
            {generateDataCreditAccount()}
          </View>
          <View
            style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
};

export default PrintVoucherList;
