import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import config from "../../config";
import AccountDropDown from "../Shared/AccountDropDown";
import ProductsDropDown from "../Shared/ProductsDropDown";
import moment from "moment";
import BoxCard from "../Shared/BoxCard";
import {
  Space,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  DatePicker,
  Form,
  Select,
} from "antd";
import { NavLink } from "react-router-dom";
import Header from "../Layout/Header";
import ReportsMenu from "./ReportsMenu";
import { PrinterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { render } from "@testing-library/react";
import LoginChecked from "../Shared/LoginChecked";
function ProfitOnSales() {
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [orderby, SetOrderBy] = useState(1);
  const [EndDate, SetEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [StartDate, SetStartDate] = useState(dayjs().subtract(1, "month").format("YYYY-MM-DD"));
  const [ProductID, SetProductID] = useState(0);
  const [SupplierID, SetSupplierID] = useState(0);
  const [ShowDetails, SetShowDetails] = useState(true);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [AccountID,SetAccountID] = useState("");
  const [ProfitOnSale, setProfitOnSale] = useState([]);
  const accounts = AccountDropDown();
  const Products = ProductsDropDown();



  useEffect(() => {
    document.title = "Profit on Sales Report";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      ProductID: ProductID,
      SupplierID: SupplierID,
      ShowDetails: ShowDetails,
    };
    console.log("this is data", data);
    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetProfitOnSalesReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setListOfRecords(response.data.ListofProducts);
          setProfitOnSale(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [EndDate, StartDate,ProductID,AccountID]);

  const columns = [
    {
      title: "Sr. #",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Dated",
      dataIndex: "Created_Date",
      key: "Dated",
      render: (text, record) => {
        const formattedDate = moment(record.Created_Date).format("DD MMM YYYY"); // Format date as "21 Oct 2022"
        return <span>{formattedDate}</span>;
      }
    },
    {
      title: "Product",
      dataIndex: "Name",
      key: "Product",
      // render: (_, record) => record.Product_BE?.Name,
    },
    {
      title: "	Quantity",
      dataIndex: "Balance",
      key: "	Quantity",
      render: (text) => {
        return Number(text).toFixed(2);
      }
    },
    {
      title: "Sale Price",
      dataIndex: "Sale_Price",
      key: "SalePrice",
      align: "right",
      render: (text) => {
        return Number(text).toFixed(2);
      }
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      align: "right",
      render: (text) => {
        return Number(text).toFixed(2);
      }
    },
    {
      title: "Buying Price	",
      dataIndex: "Last_Purchase_Price",
      key: "BuyingPrice	",
      align: "right",
      render: (text) => {
        return Number(text).toFixed(2);
      }
    },
    {
      title: "Profit",
      dataIndex: "Profit",
      key: "Profit",
      align: "right",
      render: (text) => {
        return Number(text).toFixed(2);
      }
    },
  ];

  const handleAccountChange = (value) => {
    SetAccountID(value);
  };
  const handleProductChange = (value) => {
    SetProductID(value);
  };

  // const getFooterContent = () => (
  //   <Row gutter={[16, 16]}>
  //     {/* First Column */}
  //     <Col md={6} xs={24}>
  //       <Row>
  //         <Col span={8}>
  //           <p>
  //             <b>Total Sale:</b>
  //           </p>
  //         </Col>
  //         <Col md={6} xs={16} style={{ textAlign: "right" }}>
  //           <p>{ProfitOnSale.TotalSales}</p>
  //         </Col>
  //       </Row>
  //     </Col>

  //     {/* Second Column */}
  //     <Col md={6} xs={24}>
  //       <Row>
  //         <Col span={15}>
  //           <p>
  //             <b>Gross Profit On Sale:</b>
  //           </p>
  //         </Col>
  //         <Col md={6} xs={9} style={{ textAlign: "right" }}>
  //           <p>{ProfitOnSale.ProfitOnSales}</p>
  //         </Col>
  //       </Row>
  //     </Col>
  //     {/* Third Column */}
  //     <Col md={6} xs={24}>
  //       <Row>
  //         <Col span={11}>
  //           <p>
  //             <b>Total Expense:</b>
  //           </p>
  //         </Col>
  //         <Col md={6} xs={13} style={{ textAlign: "right" }}>
  //           <p>{ProfitOnSale.TotalExpenes}</p>
  //         </Col>
  //       </Row>
  //     </Col>
  //     {/* Forth Column */}
  //     <Col md={6} xs={24}>
  //       <Row>
  //         <Col span={13}>
  //           <p>
  //             <b>Net Gross Profit:</b>
  //           </p>
  //         </Col>
  //         <Col md={6} xs={11} style={{ textAlign: "right" }}>
  //           <p>{ProfitOnSale.GrossProfit}</p>
  //         </Col>
  //       </Row>
  //     </Col>
  //   </Row>
  // );

  const onDateChange = (date, value) => {
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const disabledDate=(current)=>{
    return current && current>moment().endOf('day');
  }

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Profit On Sales</h3>

            <div className="header-actions">
              <NavLink
                to={`/report-profitonsale/print-list/?StartDate=${StartDate}&EndDate=${EndDate}&ProductID=${ProductID}&SupplierID=${SupplierID}`}
                target="_blank"
              >
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  // style={{ marginBottom: 10 }}
                >
                  <PrinterOutlined />
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>

          <div className="card-total-detal">
            <Row gutter={[16,16]} style={{ marginBottom: "16px" }}>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Total Sale"
                  amount={ProfitOnSale.TotalSales}
                  prefix="Rs."
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="#6FB2FF"
                      class="size-6"
                      viewBox="0 0 30 30"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Gross Profit On Sale"
                  amount={ProfitOnSale.ProfitOnSales}
                  prefix="Rs."
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="#6FB2FF"
                      class="size-6"
                      viewBox="0 0 30 30"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
              <Col md={6}>
                <BoxCard
                loading={loading}
                  title="Total Expense"
                  amount={ProfitOnSale.TotalExpenes}
                  prefix="Rs."
                  icon={
                    <svg
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#6FB2FF"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Net Gross Profit"
                  amount={ProfitOnSale.GrossProfit}
                  prefix="Rs."
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      stroke="#6FB2FF"
                      class="size-6"
                      viewBox="0 0 30 30"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
            </Row>
            <Row gutter={[16,16]} style={{ marginBottom: "16px" }}>
            <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Total Discounts"
                  amount={ProfitOnSale.TotalDiscount}
                  prefix="Rs."
                  icon={
                    <svg
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#6FB2FF"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
              <Col md={6}>
                <BoxCard
                  loading={loading}
                  title="Total Withdrawal"
                  amount={ProfitOnSale.TotalWithDrawal}
                  prefix="Rs."
                  icon={
                    <svg
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      stroke="#6FB2FF"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>
                  }
                />
              </Col>
            </Row>
          </div>

          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <RangePicker onChange={onDateChange}
                disabledDate={disabledDate} />
              </Form.Item>
              <Form.Item >
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: "160px",
                  }}
                  placeholder="All Products"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={Products ? false : true}
                  options={Products}
                  onChange={handleProductChange}
                />
              </Form.Item>
              <Form.Item >
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: "160px",
                  }}
                  placeholder="All Customers"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={accounts ? false : true}
                  options={accounts}
                  onChange={handleAccountChange}
                />
              </Form.Item>
            </Form>
          </div>
          <div style={{marginBottom:"50px"}}>
          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            dataSource={ListOfRecords}
            // footer={getFooterContent}
            // onChange={handleTableChange}
            pagination={false}
            bordered
          />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfitOnSales;
