import React, { useEffect, useState } from "react";
import HeaderPrint from "./PrintHeaderAccount";
import axios from "axios";
import config from "../../../config";
import { calculateColumnTotal } from "../../Shared/Utility";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { locale } from "dayjs";
import { Spin } from "antd";

const PrintCashReceivable = () => {
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const urlParams = new URLSearchParams(window.location.search);

  const PageNo = urlParams.get("currentPage");
  const EndDate = urlParams.get("EndDate");
  const OrderBy = urlParams.get("orderby");

  const [CashReceiveableList, setCashReceiveableList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageNo: 1,
      PageSize: 10000,
      EndDate: EndDate,
      OrderBy: OrderBy,
    };
    console.log("this is data", data);
    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetReceiveableReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setCashReceiveableList(response.data.listofLedger);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
      //flexGrow: 1
    },
    thFooter: {
      flex: 1,
      padding: 5,
      fontSize: 10,
    },
    valueFooter: {
      fontSize: "8pt",
      fontWeight: "bold",
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    table: {
      display: "flex",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    th: {
      //flex: 1,
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      flexShrink: 0,
    },
    thValue: {
      fontSize: "8pt",
      fontWeight: "bold",
    },

    row: {
      flexDirection: "row",
    },
    td: {
      //flex: 1,
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      flexShrink: 0,
    },
    tdValue: {
      fontSize: "8pt",
      color: "black",
    },
  });

  const generateData = () => {
    if (CashReceiveableList.length > 0) {
      return CashReceiveableList.map((item, index) => {
        return (
          <View key={index} style={styles.row}>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{item.ID}</Text>
            </View>
            <View style={[styles.td, { width: 150 }]}>
              <Text style={styles.tdValue}>{item.Name}</Text>
            </View>

            <View style={[styles.td, { width: 100 }]}>
              <Text style={styles.tdValue}>{item.Mobile_No}</Text>
            </View>
            <View style={[styles.td, { width: 100 }]}>
              <Text style={styles.tdValue}>{item.Account_Type_BE?.Name}</Text>
            </View>
            <View style={[styles.td, { width: 120 }]}>
              <Text style={[styles.tdValue, { textAlign: "right" }]}>
                {item.Balance}
              </Text>
            </View>
            <View style={[styles.td, { width: 80 }]}>
              <Text style={styles.tdValue}>{item.BalanceType}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <HeaderPrint  />

        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={{ fontSize: "12pt" }}>Cash Receivable</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>SR.</Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>ID</Text>
              </View>
              <View style={[styles.th, { width: 150 }]}>
                <Text style={styles.thValue}>Name</Text>
              </View>

              <View style={[styles.th, { width: 100 }]}>
                <Text style={styles.thValue}>Mobile No</Text>
              </View>
              <View style={[styles.th, { width: 100 }]}>
                <Text style={styles.thValue}>Account Type</Text>
              </View>
              <View style={[styles.th, { width: 120 }]}>
                <Text style={[styles.thValue, { textAlign: "right" }]}>
                  Current Balance
                </Text>
              </View>
              <View style={[styles.th, { width: 80 }]}>
                <Text style={styles.thValue}>Type</Text>
              </View>
            </View>

            {generateData()}

            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}></Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}></Text>
              </View>
              <View style={[styles.th, { width: 150 }]}>
                <Text style={styles.thValue}></Text>
              </View>

              <View style={[styles.th, { width: 100 }]}>
                <Text style={styles.thValue}></Text>
              </View>
              <View style={[styles.th, { width: 100 }]}>
                <Text
                  style={[
                    styles.thValue,
                    { textAlign: "right", fontWeight: "bold" },
                  ]}
                >
                  Total
                </Text>
              </View>
              <View style={[styles.th, { width: 120 }]}>
                <Text
                  style={[
                    styles.thValue,
                    { textAlign: "right", fontWeight: "bold" },
                  ]}
                >
                  {Math.round(
                    calculateColumnTotal(CashReceiveableList, "Balance")
                  )}
                </Text>
              </View>
              <View style={[styles.th, { width: 80 }]}>
                <Text style={styles.thValue}>Debit</Text>
              </View>
            </View>
          </View>
          <View
            style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="CashReceivable.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
};

export default PrintCashReceivable;
