import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Select,
  Form,
  Input,
  Row,
  DatePicker,
  Tooltip,
  message,
  Spin,
} from "antd";
import axios from "axios";
import config from "../../config";
import ProductsDropDown from "../Shared/ProductsDropDown";
import AccountDropDown from "../Shared/AccountDropDown";
import dayjs from "dayjs";
import LoginChecked from "../Shared/LoginChecked";
import {
  DeleteOutlined,
  PrinterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { NavLink, useParams,useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";

function AddPurchase() {
  let params = useParams();
  const [form] = Form.useForm();

  const navigate = useNavigate();

  // const purchase_id = urlParams.get('purchase_id');

  const productTypesArray = ProductsDropDown();
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [purchaseList, setPurchaseList] = useState([]);
  const [Quantity, setQuantity] = useState(0);
  const [Price, setPrice] = useState(0);
  const [ProductName, setProductName] = useState("");
  const [AccountName, setAccountName] = useState("");
  const [Dated, setDated] = useState(null);
  const [Description, setDescription] = useState("");
  const [Reference_No, setReferenceNo] = useState("");
  const [voucherNo, setVoucherNo] = useState("");
  const [loading, setLoading] = useState(false);

  const [loadingSave, setLoadingSave] = useState(false);

  const [TotalCredit, setTotalCredit] = useState(0);
  const [TotalDebit, setTotalDebit] = useState(0);
  const [TotalPetrol, setTotalPetrol] = useState(0);
  const [TotalDiesel, setTotalDiesel] = useState(0);
  const [InvoiceNo, setInvoiceNo] = useState("");

  const [Purchase, SetPurchase] = useState([]);

  const [totals, setTotals] = useState({
    cashSaleTotal: 0,
    creditSaleTotal: 0,
    totalPrice: 0,
  });

  const [TestPrice, setTestPrice] = useState("");

  const [invoiceSaved, setInvoiceSaved] = useState(false);

  // loadings
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [ListOfAccounts, setListOfAccounts] = useState([]);
  const [priceGroup, setpriceGroup] = useState([]);
  const [loadingGetPurchase, setLoadingGetPurchase] = useState(false);

  const [IsUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    document.title = "Edit Purchase";
    setLoadingGetPurchase(true);
    setIsUpdated(false);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Purchase/GetPurchaseByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    //console.log("🚀 ~ useEffect ~ data:", data)
    axios(api_config)
      .then(function (response) {
        console.log("GetPurchaseByID", response.data);
        if (response.data.status_code == 1) {
          SetPurchase(response.data.listofPurchase);
          setVoucherNo(response.data.InvoiceNo);
          setDescription(response.data.Description);
          setReferenceNo(response.data.Reference_No);
          setDated(dayjs(response.data.Dated));
          setLoadingGetPurchase(false);
        } else {
          setLoadingGetPurchase(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!loadingGetPurchase) {
      const list = Purchase.map((item) => ({
        ProductName: item.ProductName,
        AccountName: item.AccountName,
        Product_ID: item.Product_ID,
        Account_ID: item.Account_ID,
        Vehicle_No: item.Vehicle_No,
        Quantity: item.Quantity,
        Price: item.Price,
        Is_Cash: item.Is_Cash,
      }));

      setPurchaseList(list);
    }
  }, [Purchase]);

  useEffect(() => {
    setLoadingAccounts(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      // ID: purchase_id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Account/GetAccountForPurchase",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          let list = response.data.listofAccounts;

          if (list) {
            const result = list.map((item) => ({
              value: item.ID,
              label: item.Name,
            }));

            setListOfAccounts(result);
          }

          setLoadingAccounts(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleSubmit = (formData) => {
    formData["Is_Cash"] = formData["Is_Cash"] == true ? false : true;
    formData["Vehicle_No"] =
      formData["Vehicle_No"] == undefined ? "" : formData["Vehicle_No"];

    const dataa = {
      ProductName: ProductName,
      AccountName: AccountName,
      ...formData,
    };

    setPurchaseList([...purchaseList, dataa]);

    form.resetFields();
    setQuantity(0);
    setPrice(0);
  };

  function handleCheckboxChange(index) {
    setPurchaseList((prevData) => {
      const newData = [...prevData];
      newData[index].Is_Cash = !newData[index].Is_Cash;
      return newData;
    });
  }

  useEffect(() => {
    let cashTotal = 0;
    let creditTotal = 0;
    let TotalPetrolPurchase = 0;
    let TotalDieselPurchase = 0;

    purchaseList.forEach((item) => {
      if (item.Is_Cash == true) {
        cashTotal += parseInt(item.total); // Use parseInt to convert string to number
      } else {
        creditTotal += parseInt(item.total);
      }

      if (item.ProductName.toLowerCase().includes("petrol")) {
        TotalPetrolPurchase += parseInt(item.total);
      } else if (item.ProductName.toLowerCase().includes("diesel")) {
        TotalDieselPurchase += parseInt(item.total);
      }
    });

    setTotalDebit(cashTotal);
    setTotalCredit(creditTotal);
    setTotalPetrol(TotalPetrolPurchase);
    setTotalDiesel(TotalDieselPurchase);

    // console.log(purchaseList);
  }, [purchaseList]);

  useEffect(() => {
    // Convert Price and Quantity to numbers and calculate the total whenever they change
    const priceValue = parseFloat(Price);
    const quantityValue = parseFloat(Quantity);
    const totalValue = priceValue * quantityValue;
    const total = isNaN(totalValue) ? "" : totalValue.toString();
    form.setFieldsValue({
      total: total,
    });
  }, [Price, Quantity]);

  const handleQuantity = (e) => {
    const quantity = e.target.value;
    setQuantity(quantity);

    if (!isNaN(parseFloat(Price))) {
      const totalValue = parseFloat(Price) * parseFloat(quantity);
      const total = isNaN(totalValue) ? "" : totalValue.toString();
      form.setFieldsValue({
        Total: total,
      });
    }
  };

  const handlePrice = (e) => {
    const price = e.target.value;
    setPrice(price);

    if (!isNaN(parseFloat(Quantity))) {
      const totalValue = parseFloat(Quantity) * parseFloat(price);
      const total = isNaN(totalValue) ? "" : totalValue.toString();
      form.setFieldsValue({
        total: total,
      });
    }
  };

  const handleTotalChange = () => {
    const total = form.getFieldValue("Total");
    const price = form.getFieldValue("Price");
    if (total && price) {
      const quantity = total / price;
      form.setFieldsValue({ Quantity: quantity.toFixed(2) });
    }
  };

  const handleProductChange = (value, option) => {
    setProductName(option.label);
    setTestPrice(option.TestPrice);
    form.setFieldValue("Price", option.price);
    setPrice(option.price);
  };
  const handleAccountChange = (value, option) => {
    setAccountName(option.label);
  };

  const handleSaveInvoice = () => {
    setLoadingSave(true);

    const modifiedData = purchaseList.map(
      ({ AccountName, ProductName, ...rest }) => rest
    );

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Dated: Dated,
      Description,
      Reference_No,
      ListofPurchaseDetails: modifiedData,
      ID: params.id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Purchase/UpdatePurchase",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          message.success(response.data.status_message);
          setInvoiceNo(response.data.InvoiceNo);
          // Handle success
          setInvoiceSaved(true);
          setLoadingSave(false);
        } else {
          // Handle error
          setLoadingSave(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDelete = (index) => {
    const updatedItems = [...purchaseList];
    // Remove the item at the specified index
    updatedItems.splice(index, 1);
    // Update the state with the new array
    setPurchaseList(updatedItems);
  };

  useEffect(() => {
    const groupedData = purchaseList.reduce((acc, obj) => {
      const key = obj.Product_ID;
      if (!acc[key]) {
        acc[key] = {
          Product_ID: obj.Product_ID,
          ProductName: obj.ProductName,
          Total_Price: 0,
          Quantity: obj.Quantity,
        };
      }
      acc[key].Total_Price += parseInt(obj.Quantity) * parseInt(obj.Price);
      return acc;
    }, {});

    setpriceGroup(Object.values(groupedData));
  }, [purchaseList]);

  useEffect(() => {
    const cashSaleTotal = purchaseList.reduce((acc, obj) => {
      if (obj.Is_Cash) {
        return acc + parseInt(obj.Quantity) * parseInt(obj.Price);
      }
      return acc;
    }, 0);

    const creditSaleTotal = purchaseList.reduce((acc, obj) => {
      if (!obj.Is_Cash) {
        return acc + parseInt(obj.Quantity) * parseInt(obj.Price);
      }
      return acc;
    }, 0);

    const totalPrice = purchaseList.reduce((acc, obj) => {
      return acc + parseInt(obj.Quantity) * parseInt(obj.Price);
    }, 0);

    setTotals({
      cashSaleTotal,
      creditSaleTotal,
      totalPrice,
    });
  }, [purchaseList]);

  const handleDateChange = (date, value) => {
    setDated(value);
  };


  const goBack=()=>{
    navigate(-1);
  }
  return (
    <>
      <LoginChecked />

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">
              <NavLink onClick={goBack}>
                <ChevronLeftIcon />
              </NavLink>
              Edit Purchase
            </h6>
          </div>

          <Row gutter={[10, 20]}>
            {loadingGetPurchase ? <>
                <Spin style={{height:"100vh", width:"100%",display:"flex",alignItems:"center",justifyContent:"center"}} />
            </>:<Form
              name="basic"
              autoComplete="off"
              className="responsive-form"
              fields={[
                {
                  name: "InvoiceNo",
                  value: voucherNo,
                },
                {
                  name: "Reference_No",
                  value: Reference_No,
                },
                {
                  name: "Description",
                  value: Description,
                },
              ]}
            >
              <Row gutter={24}>
                <Col md={4} xs={24}>
                  <Form.Item
                    className="input-vertical"
                    label="Invoice No"
                    name="InvoiceNo"
                    rules={[
                      {
                        required: true,
                        message: "Enter invoice number",
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Item
                    className="input-vertical"
                    label="Dated"
                    name="Dated"
                  >
                    <DatePicker
                      defaultValue={Dated}
                      onChange={handleDateChange}
                    />
                  </Form.Item>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    className="input-vertical"
                    label="Ref No"
                    name="Reference_No"
                  >
                    <Input onChange={(e) => setReferenceNo(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col md={10} xs={24}>
                  <Form.Item
                    className="input-vertical"
                    label="Description"
                    name="Description"
                  >
                    <Input onChange={(e) => setDescription(e.target.value)} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>}
            
          </Row>

          <Form
            name="basic"
            layout="horizontal"
            form={form}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            // initialValues={{
            //     remember: true,
            //     Vehicle_No: voucherNo
            // }}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <div className="table-responsive">
              <table className="add-sale-table">
                <thead>
                  <tr>
                    <th>SR</th>
                    <th>Product</th>
                    <th>Account</th>
                    <th>Vehicle No</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                    <th>Cash</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseList.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.ProductName}</td>
                      <td>{item.AccountName}</td>
                      <td>{item.Vehicle_No}</td>
                      <td>{item.Quantity}</td>
                      <td>{item.Price}</td>
                      <td>{item.Price * item.Quantity}</td>
                      <td>
                        <Checkbox
                          checked={item.Is_Cash}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>

                      <td>
                        <DeleteOutlined onClick={(e) => handleDelete(index)} />
                      </td>
                    </tr>
                  ))}
                  <tr className="table-form">
                    <td></td>
                    <td>
                      <Form.Item
                        name="Product_ID"
                        rules={[
                          {
                            required: true,
                            message: "Select product",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Product"
                          loading={productTypesArray === false ? true : false}
                          options={productTypesArray}
                          optionFilterProp="label" // Specify the property to be used for filtering
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={handleProductChange}
                          style={{ width: "200px" }}
                        />
                      </Form.Item>
                    </td>

                    <td>
                      <Form.Item
                        name="Account_ID"
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please select an account!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Account"
                          optionFilterProp="children"
                          onChange={handleAccountChange}
                          filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                          }
                          loading={loadingAccounts}
                          options={ListOfAccounts}
                          style={{ width: "150px" }}
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="Vehicle_No">
                        <Input placeholder="Vehicle Number" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="Quantity"
                        rules={[
                          {
                            required: true,
                            message: "Please enter quantity!",
                          },
                        ]}
                      >
                        <Input onChange={handleQuantity} placeholder="Qty" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="Price"
                        rules={[
                          {
                            required: true,
                            message: "Please enter price!",
                          },
                        ]}
                      >
                        <Input onChange={handlePrice} placeholder="Price" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="Total">
                        <Input
                          onChange={handleTotalChange}
                          placeholder="Total"
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="Is_Cash">
                        <Checkbox>Cash</Checkbox>
                      </Form.Item>
                    </td>
                    <td>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="small"
                        shape="round"
                        loading={loading}
                      >
                        Add
                      </Button>
                    </td>
                  </tr>

                  {totals.totalPrice > 0 && (
                    <>
                      <tr>
                        <td colSpan={6} style={{ textAlign: "right" }}>
                          Total Sale
                        </td>
                        <td colSpan={3}>
                          <strong>{totals.totalPrice}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6} style={{ textAlign: "right" }}>
                          Total Cash Sale
                        </td>
                        <td colSpan={3}>{totals.cashSaleTotal}</td>
                      </tr>
                      <tr>
                        <td colSpan={6} style={{ textAlign: "right" }}>
                          Total Credit Sale
                        </td>
                        <td colSpan={3}>{totals.creditSaleTotal}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Form>

          <ul className="total-price">
            {priceGroup.map((item) => (
              <li key={item.Product_ID}>
                <p>{item.ProductName}</p>
                <h4>Qty: {item.Quantity}</h4>
                <h4>Rs. {item.Total_Price}</h4>
              </li>
            ))}
          </ul>

          <div
            style={{
              justifyContent: "flex-end",
              marginTop: "30px",
              display: "flex",
              gap: "10px",
            }}
          >
            <Button
              onClick={handleSaveInvoice}
              type="primary"
              size="large"
              shape="round"
              loading={loadingSave}
            >
              Update Invoice
            </Button>
          </div>

          {/* <Row gutter={[24, 24]} style={{ marginTop: "20px" }}>

            <Col span={15}>
              <Card title="PURCHASE LIST">
               

                <Row gutter={[19, 16]} style={{ marginTop: "20px" }}>
                  <Col span={12}>
                    <div className="icon-box">
                      <div
                        className="icons-card"
                        style={{ marginLeft: "20px" }}
                      >
                        <span className="material-symbols-outlined">
                          arrow_downward
                        </span>
                      </div>
                      <div style={{ marginLeft: "20px" }}>
                        <a href="#">
                          <p>Cash Total</p>
                        </a>
                        <h3 className="content-height">{TotalDebit}</h3>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="icon-box">
                      <div
                        className="icons-card"
                        style={{ marginLeft: "20px" }}
                      >
                        <span className="material-symbols-outlined">
                          arrow_upward
                        </span>
                      </div>
                      <div style={{ marginLeft: "20px" }}>
                        <a href="#">
                          <p>Credit Total</p>
                        </a>
                        <h3 className="content-height">{TotalCredit}</h3>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="icon-box">
                      <div
                        className="icons-card"
                        style={{ marginLeft: "20px" }}
                      >
                        <span className="material-symbols-outlined">
                          monitoring
                        </span>
                      </div>
                      <div style={{ marginLeft: "20px" }}>
                        <a href="#">
                          <p>Total Petrol Purchase</p>
                        </a>
                        <h3 className="content-height">{TotalPetrol}</h3>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="icon-box">
                      <div
                        className="icons-card"
                        style={{ marginLeft: "20px" }}
                      >
                        <span className="material-symbols-outlined">
                          monitoring
                        </span>
                      </div>
                      <div style={{ marginLeft: "20px" }}>
                        <a href="#">
                          <p>Total Diesel Purchase</p>
                        </a>
                        <h3 className="content-height">{TotalDiesel}</h3>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row justify="space-between" style={{ marginTop: "20px" }}>
                  <Col>
                    <Button
                      type="primary"
                      onClick={handleSaveInvoice}
                      htmlType="submit"
                      size="large"
                      shape="round"
                      loading={loading}
                    >
                      Save Invoice
                    </Button>
                  </Col>
                  {invoiceSaved && (
                    <Col>
                      <NavLink
                        to={`/purchase/print-invoice/?purchase_id=${InvoiceNo}`}
                      >
                        <Button loading={loading} icon={<PrinterOutlined />}>
                          Print Invoice
                        </Button>
                      </NavLink>
                    </Col>
                  )}
                </Row>
              </Card>
            </Col>
          </Row> */}
        </div>
      </div>
    </>
  );
}

export default AddPurchase;
