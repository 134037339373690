import React, { useState } from "react";
import ProductsDropDown from "../Shared/ProductsDropDown";
import axios from "axios";
import config from "../../config";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import LoginChecked from "../Shared/LoginChecked";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  Input,
  Select,
  message,
  Spin,
} from "antd";
import { NavLink } from "react-router-dom";

function AddPumpMachine() {
  const { Option } = Select;
  const [messageApi, contextHolder] = message.useMessage();

  const productTypesArray = ProductsDropDown();
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");

  const [loading, setLoading] = useState(false);

  useState(() => {
    document.title = "Add Pump Machine";
  }, []);

  const handleSubmit = (formData) => {
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ...formData,
    };

    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Product/AddNewNozzle",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // console.log(api_config);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          // Handle success
        } else {
          // Handle error
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">
              <NavLink to="/products/manage-machines">
                <ChevronLeftIcon />
              </NavLink>
              Add Pump Machine
            </h6>
          </div>
          <Row gutter={[24, 24]}>
            <Col span={12} xs={24}>
              <Card title="Add Pump Machine">
                <Form
                  name="basic"
                  layout="horizontal"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={handleSubmit}
                  //    onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Pump No"
                    name="Pump_No"
                    rules={[
                      {
                        required: true,
                        message: "Please enter pump number",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Pump Number" />
                  </Form.Item>
                  <Form.Item
                    label="Product Type"
                    name="Product_ID"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Product"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={productTypesArray === false ? true : false}
                      options={productTypesArray}
                    />
                  </Form.Item>
                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      htmlType="submit"
                      loading={loading}
                    >
                      {loading ? <Spin /> : "Save Machine"}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AddPumpMachine;
