import React, { useEffect, useState } from "react";

import axios from "axios";
import config from "../../config";
import LoginChecked from "../Shared/LoginChecked";

import {
  Space,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  DatePicker,
  Form,
  Select,
  message,
  Tooltip,
} from "antd";
import moment from "moment";
import { NavLink } from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  PrinterOutlined,
  PlusOutlined,
} from "@ant-design/icons";

function ManagePurchase() {
  const [currentPage, setCurrentPage] = useState(1);

  const { RangePicker } = DatePicker;

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [listOfPurchases, SetlistOfPurchases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleted,setIsDeleted]=useState(false);

  const [StartDate, SetStartDate] = useState("");
  const [EndDate, SetEndDate] = useState("");
  const [Cash, SetCash] = useState("");
  const [Credit, SetCredit] = useState("");
  const [TotalPurchases, SetTotalPurchases] = useState("");

  const [SumTotal, SetSumTotal] = useState("");
  // const[isDeleted, setIsDeleted] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    
    document.title = "Manage Purchases";

    setLoading(true);
    setIsDeleted(false);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      PageNo: pageNo,
      PageSize: pageSize,
    };

    //console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Purchase/GetPurchases",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log("list", response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistOfPurchases(response.data.listofPurchase);
          SetCash(response.data.CashTotal);
          SetCredit(response.data.CreditTotal);
          SetTotalPurchases(response.data.TotalPurchases);
          setTotalRecords(response.data.totalRecords);
          // SetSumTotal(response.data.CashTotal + response.data.CreditTotal)

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate, pageNo, pageSize,isDeleted]);

  const handelDelete = (ID) => {
    setLoading(true);
    setIsDeleted(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: ID,
    };
    console.log("data", data);
    const api_config = {
      method: "post",
      url: config.base_url + "Purchase/DeletePurchase",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log("response", response.data);
        if (response.data.status_code === 1) {
          setLoading(false);
          message.success(response.data.status_message);
        } else {
          setLoading(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Sr.#",
      dataIndex: "sr",
      key: "sr",
      render: (_, record, index) => index + 1 + pageSize * (pageNo - 1),
    },
    {
      title: "ID",
      dataIndex: "ID",
      key: "id",
    },
    {
      title: "Invoice No",
      dataIndex: "InvoiceNo",
      key: "invoiceno",
    },
    {
      title: "Dated",
      dataIndex: "Dated", // Use the path to the nested object
      key: "date",
      render: (date) => moment(date).format(config.date_format),
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "description",
    },
    {
      title: "Cash",
      dataIndex: "Cash_Total",
      key: "cash",
      align: "right",
    },
    {
      title: "Credit",
      dataIndex: "Credit_Total",
      key: "credit",
      align: "right",
    },
    {
      title: "Total",
      dataIndex: "Total",
      key: "total",
      align: "right",
      render: (_, record) => (
        <span>{record.Cash_Total + record.Credit_Total}</span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <NavLink to={`/purchase/edit-purchase/${record.ID}`}>
            <Tooltip title="Edit Purchase">
              <EditOutlined />
            </Tooltip>
          </NavLink>
          <Popconfirm
            title="Sure to delete?"
            discription="Are You sure to delete this data"
            onConfirm={(e) => handelDelete(record.ID)}
          >
            <NavLink to="#">
              <Tooltip title="Delete">
                <DeleteOutlined />
              </Tooltip>
            </NavLink>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const onPageChange = (page, pageSize) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1); // Reset to the first page when changing page size
    setPageSize(size);
  };

  // Calculate the start and end index of the data to display
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const listOfPurchasess = listOfPurchases.slice(startIndex, endIndex);

  const getFooterContent = () => (
    <Row gutter={[16, 16]}>
      {/* First Column */}
      <Col md={8} xs={24}>
        <Row>
          <Col md={8} xs={16}>
            <p>
              <b>Total Cash:</b>
            </p>
          </Col>
          <Col md={16} xs={8} style={{ textAlign: "right" }}>
            <p>{Cash}</p>
          </Col>
        </Row>
      </Col>
      <Col md={8} xs={24}>
        <Row>
          <Col md={8} xs={16}>
            <p>
              <b>Total Credit:</b>
            </p>
          </Col>
          <Col md={16} xs={8} style={{ textAlign: "right" }}>
            <p>{Credit}</p>
          </Col>
        </Row>
      </Col>
      <Col md={8} xs={24}>
        <Row>
          <Col md={8} xs={16}>
            <p>
              <b>Total Amount:</b>
            </p>
          </Col>
          <Col md={16} xs={8} style={{ textAlign: "right" }}>
            <p>{Cash + Credit}</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const handleDateChange = (date, value) => {
    console.log(value);
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  // const deleteData=(ID)=>{
  //     setLoading(true);
  //     const data ={
  //         AccessKey:AccessKey,
  //         UserID:UserID,
  //         ID:ID,
  //     }

  //     var api_config={
  //         method:"post",
  //         url:config.base_url + 'Purchase/GetPurchases',
  //         headers:{
  //             "Content-Type": "application/json",
  //         },
  //         data:data,
  //     }
  //     console.log("delete data",data)

  //     axios(api_config)
  //     .then(function(response){
  //         if(response.data.status_code == 1 ){
  //       message.success(response.data.status_message);
  //       setLoading(false);
  //       setIsDeleted(true);
  //         }
  //         else{
  //             message.error(response.data.status_code_message);
  //             setLoading(false);
  //         }
  //     })
  //     .catch(function(error){
  //         console.Console.log(error)
  //     });

  // }

  return (
    <>
      <LoginChecked />
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Purchases</h3>
            <div className="header-actions">
              <NavLink
                to={`/purchase/print-purchase-list/?StartDate=${StartDate}&EndDate=${EndDate}`}
                target="blank"
              >
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  icon={<PrinterOutlined />}
                >
                  Print Report
                </Button>
              </NavLink>
              <NavLink to="/purchase/add-purchase">
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  icon={<PlusOutlined style={{color:"white"}} />}
                >
                  Add Purchase
                </Button>
              </NavLink>
            </div>
          </div>

          <div className="filters-wrap">
            <Form>
              <RangePicker allowClear onChange={handleDateChange}
              disabledDate={disabledDate}
              />
            </Form>
          </div>

          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            dataSource={listOfPurchasess}
            footer={getFooterContent}
            pagination={false}
            bordered
          />
          <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange} // Handle page size change
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ManagePurchase;
