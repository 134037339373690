import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Routes, Route } from "react-router-dom";

//import 'bootstrap/dist/css/bootstrap.min.css';

import "./assets/css/style.css";
import Main from "./components/Layout/Main";
import ManageAccounts from "./components/Accounts/ManageAccounts";
import AddPurchase from "./components/Purchase/AddPurchase";
import ManagePurchase from "./components/Purchase/ManagePurchase";
import AddSales from "./components/Sales/AddSales";
import ViewSales from "./components/Sales/ViewSales";
import AddVoucher from "./components/Vouchers/AddVoucher";
import ManageVouchers from "./components/Vouchers/ManageVouchers";
import PaymentReceipts from "./components/Vouchers/PaymentReceipts";
import DipSettings from "./components/DipSettings/DipSettings";
import Summary from "./components/DipSettings/Summary";
import ManageTanks from "./components/DipSettings/ManageTanks";
import ChangeCompanyInfo from "./components/Security/ChangeCompanyInfo";
import CashReceiveable from "./components/Reoprts/CashReceiveable";
import AddAccount from "./components/Accounts/AddAccount";
import ChangePassword from "./components/Security/ChangePassword";

import CashPayable from "./components/Reoprts/CashPayable";
import DateWiseProductSummary from "./components/Reoprts/DateWiseProductSummary";
import DateWiseProductWiseSale from "./components/Reoprts/DateWiseProductWiseSale";
import DateWiseSummary from "./components/Reoprts/DateWiseSummary";
import ExpensesReport from "./components/Reoprts/ExpensesReport";
import ProfitOnSales from "./components/Reoprts/ProfitOnSales";
import PurchasesReport from "./components/Reoprts/PurchasesReport";
import SalesReport from "./components/Reoprts/SalesReport";
import SummarySheet from "./components/Reoprts/SummarySheet";
import TradingAccounts from "./components/Reoprts/TradingAccounts";
import Login from "./components/Auth/Login";
import AddNewProduct from "./components/Products/AddNewProduct";
import AddPumpMachine from "./components/Products/AddPumpMachine";
import ManageProduct from "./components/Products/ManageProduct";
import ManageMachines from "./components/Products/ManageMachines";
import RateAdjustForIncome from "./components/Products/RateAdjustForIncome";
import ManageUsers from "./components/Security/ManageUsers";
import AdjustLedger from "./components/Security/AdjustLedger";
import LoginLogs from "./components/Security/LoginLogs";
import SignUp from "./components/Auth/SignUp";
import AddTank from "./components/DipSettings/AddTank";
import AddUser from "./components/Security/AddUser";
import Stock from "./components/Dashboard/Stock";
import Sales from "./components/Dashboard/Sales";
import Analytics from "./components/Dashboard/Analytics";
import PrintAccountLedger from "./components/Accounts/Reports/PrintAccountLedger";
import AccountLedger from "./components/Accounts/Reports/AccountLedger";
import PrintAccountList from "./components/Accounts/Reports/PrintAccountList";
import PrintPurchaseList from "./components/Purchase/Reports/PrintPurchaseList";
import EditAccount from "./components/Accounts/EditAccount";
import PrintHeaderAccount from "./components/Accounts/PrintHeaderAccount";
import EditProduct from "./components/Products/EditProduct";
import ProductLedger from "./components/Accounts/Reports/ProductLedger";
import PrintProductList from "./components/Products/PrintReport/PrintProductList";
import EditMachine from "./components/Products/EditMachine";
import MachineReading from "./components/Products/MachineReading";
import PrintProductLedger from "./components/Products/PrintProductLedger";
import PrintVoucherList from "./components/Vouchers/Print/PrintVoucherList";
import Forget from "./components/Auth/Forget";
import EditTank from "./components/DipSettings/EditTank";
import EditUser from "./components/Security/EditUser";
import PrintInvoice from "./components/Purchase/PrintInvoice";
import EditPurchase from "./components/Purchase/EditPurchase";
import PrintSaleList from "./components/Sales/PrintSaleList";
import EditSales from "./components/Sales/EditSales";
import CustomRate from "./components/Accounts/CustomRate";

import { ConfigProvider, theme } from "antd";
import PrintDownloadNewSale from "./components/Sales/PrintDownloadNewSale";
import PaymentReceived from "./components/Vouchers/PaymentReceived";
import PaymentGiven from "./components/Vouchers/PaymentGiven";
import ExpenseVoucher from "./components/Vouchers/ExpenseVoucher";
import PrintSummarySheet from "./components/Reoprts/Prints/PrintSummarySheet";
import PrintCashReceivable from "./components/Reoprts/Prints/PrintCashReceivable";
import PrintCashPayable from "./components/Reoprts/Prints/PrintCashPayable";
import PrintPurchaseReport from "./components/Reoprts/Prints/PrintPurchaseReport";
import PrintSaleReport from "./components/Reoprts/Prints/PrintSaleReport";
import PrintTradingReport from "./components/Reoprts/Prints/PrintTradingReport";
import PrintProfitOnSale from "./components/Reoprts/Prints/PrintProfitOnSale";
import PrintExpensReport from "./components/Reoprts/Prints/PrintExpensReport";
import PrintDatewiseSummary from "./components/Reoprts/Prints/PrintDatewiseSummary";
import PrintDateWiseSummary2 from "./components/Reoprts/Prints/PrintDateWiseSummary2";
import Billing from "./components/Billing/Billing";
import PackageExpired from "./components/Billing/PackageExpired";
import PrintDateWiseSummaryAll from "./components/Reoprts/Prints/PrintDateWiseSummaryAll";
import PrintProductWiseSummary from "./components/Reoprts/Prints/PrintProductWiseSummary";
import PrintVouchersDetail from "./components/Vouchers/Print/PrintVouchersDetail";
import PrintDipSummary from "./components/DipSettings/Print/PrintDipSummary";

function Index() {
  return (
    <ConfigProvider
      theme={{
        // algorithm: darkAlgorithm,
        //type: 'dark',

        components: {
          Button: {
            primaryColor: "#fff",
            fontWeight: "600",
            contentFontSize: 16,
            defaultBorderColor: "#2046CF",
            defaultColor: "#2046CF",
          },
          Form: {
            labelColor: "#68757D",
            labelFontSize: 16,
          },
          Menu: {
            itemColor: "#001B66",
            itemHoverBg: "#E6F3FF",
            itemHoverColor: "#001B66",
            iconSize: 18,
          },
        },

        token: {
          // Seed Token
          colorPrimary: "#2046CF",
          borderRadius: 4,

          // Alias Token
          //colorBgContainer: '#324F94',
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/package-expired" element={<PackageExpired />} />
          <Route
            path="/product/print-product-ledger"
            element={<PrintProductLedger />}
          />
          <Route
            path="/accounts/print-account-ledger"
            element={<PrintAccountLedger />}
          />
          <Route
            path="/accounts/print-header-account"
            element={<PrintHeaderAccount />}
          />
          <Route
            path="/purchase/print-purchase-list"
            element={<PrintPurchaseList />}
          />
          <Route path="/sales/print-sale-list" element={<PrintSaleList />} />
          <Route path="/purchase/print-invoice" element={<PrintInvoice />} />

          {/* {Reports} */}
          <Route
            path="/summary-report/print-list"
            element={<PrintSummarySheet />}
          />
          <Route
            path="/report-cashreceivable/print-list"
            element={<PrintCashReceivable />}
          />
          <Route
            path="/report-cashpayable/print-list"
            element={<PrintCashPayable />}
          />
          <Route
            path="/report-Purchase/print-list"
            element={<PrintPurchaseReport />}
          />
          <Route path="/report-sale/print-list" element={<PrintSaleReport />} />
          <Route
            path="/report-trading/print-list"
            element={<PrintTradingReport />}
          />
          <Route
            path="/report-profitonsale/print-list"
            element={<PrintProfitOnSale />}
          />
          <Route
            path="/report-printexpensreport/print-list"
            element={<PrintExpensReport />}
          />
          <Route
            path="/report-datewiseSummary/print-list"
            element={<PrintDatewiseSummary />}
          />
          <Route
            path="/report-datewiseSummary2/print-list"
            element={<PrintDateWiseSummary2 />}
          />
          <Route
            path="/report-datewiseSummary-all/print-list"
            element={<PrintDateWiseSummaryAll />}
          />
          <Route
            path="/report-datewiseProductSummary-all/print-list"
            element={<PrintProductWiseSummary />}
          />

          <Route path="" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<Forget />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route
            path="/accounts/print-account-list"
            element={<PrintAccountList />}
          />
          <Route
            path="/products/print-Product-list"
            element={<PrintProductList />}
          />
          <Route
            path="/accounts/print-voucher-list"
            element={<PrintVoucherList />}
          />
          <Route
            path="/vouchers/print-voucher-details"
            element={<PrintVouchersDetail />}
          />

          {/* Sales  */}

          <Route
            path="/sales/printnewsale/:id"
            element={<PrintDownloadNewSale />}
          />

          {/* Dip Summary */}

          <Route
            path="/dipsetting/printsummary/"
            element={<PrintDipSummary/>}
          />
        

          <Route path="/" element={<Main />}>
            <Route path="/dashboard/analytics" element={<Analytics />} />
            <Route path="/dashboard/stock" element={<Stock />} />
            <Route path="/dashboard/sales" element={<Sales />} />

            <Route
              path="/accounts/manage-accounts"
              element={<ManageAccounts />}
            />
            <Route path="/accounts/add-account" element={<AddAccount />} />
            <Route
              path="/accounts/edit-account/:id"
              element={<EditAccount />}
            />
            <Route
              path="/accounts/account-ledger"
              element={<AccountLedger />}
            />
            <Route path="/accounts/custom-rates" element={<CustomRate />} />

            <Route path="/purchase/add-purchase" element={<AddPurchase />} />
            <Route
              path="/purchase/edit-purchase/:id"
              element={<EditPurchase />}
            />
            <Route
              path="/purchase/manage-purchase"
              element={<ManagePurchase />}
            />

            <Route path="/sales/add-sales" element={<AddSales />} />
            <Route path="/sales/view-sales" element={<ViewSales />} />
            <Route path="/sales/edit-sales/:id" element={<EditSales />} />

            <Route path="/vouchers/add-voucher" element={<AddVoucher />} />
            <Route
              path="/vouchers/payment-received"
              element={<PaymentReceived />}
            />
            <Route
              path="/vouchers/manage-vouchers"
              element={<ManageVouchers />}
            />
            <Route
              path="/vouchers/payment-receipts"
              element={<PaymentReceipts />}
            />
            <Route path="/vouchers/payment-given" element={<PaymentGiven />} />
            <Route path="/vouchers/expense" element={<ExpenseVoucher />} />

            <Route path="/dipsettings" element={<DipSettings />} />
            <Route path="/dipsettings/manage-tanks" element={<ManageTanks />} />
            <Route path="/dipsettings/summary" element={<Summary />} />
            <Route path="/dipsettings/add-tank" element={<AddTank />} />
            <Route path="/dipsettings/edit-tank/:id" element={<EditTank />} />

            <Route
              path="/reports/cash-receiveable"
              element={<CashReceiveable />}
            />
            <Route path="/reports/cash-payable" element={<CashPayable />} />
            <Route
              path="/reports/date-wise-product-summary"
              element={<DateWiseProductSummary />}
            />
            <Route
              path="/reports/date-wise-product-wise-sale"
              element={<DateWiseProductWiseSale />}
            />
            <Route
              path="/reports/date-wise-summary"
              element={<DateWiseSummary />}
            />
            <Route
              path="/reports/expenses-report"
              element={<ExpensesReport />}
            />

            <Route
              path="/reports/purchases-report"
              element={<PurchasesReport />}
            />
            <Route
              path="/reports/datewise-productwise-sale"
              element={<DateWiseProductWiseSale />}
            />
            <Route path="/reports/sales-report" element={<SalesReport />} />
            <Route path="/reports/summary-sheet" element={<SummarySheet />} />
            <Route
              path="/reports/trading-accounts"
              element={<TradingAccounts />}
            />
            <Route
              path="/reports/profit-on-sales"
              element={<ProfitOnSales />}
            />
            <Route path="/reports/product-ledger" element={<ProductLedger />} />

            <Route
              path="/products/add-new-product"
              element={<AddNewProduct />}
            />
            <Route
              path="/products/add-pump-machine"
              element={<AddPumpMachine />}
            />
            <Route
              path="/products/manage-machines"
              element={<ManageMachines />}
            />
            <Route
              path="/products/manage-product"
              element={<ManageProduct />}
            />
            <Route
              path="/products/rate-adjust-for-income"
              element={<RateAdjustForIncome />}
            />
            <Route
              path="/products/edit-product/:id"
              element={<EditProduct />}
            />
            <Route
              path="/products/edit-machine/:id"
              element={<EditMachine />}
            />
            <Route
              path="/products/machine-reading"
              element={<MachineReading />}
            />

            <Route
              path="/security/change-company-info"
              element={<ChangeCompanyInfo />}
            />
            <Route
              path="/security/change-password"
              element={<ChangePassword />}
            />
            <Route path="/security/manage-users" element={<ManageUsers />} />
            <Route path="/security/adjust-ledger" element={<AdjustLedger />} />
            <Route path="/security/login-logs" element={<LoginLogs />} />
            <Route path="/security/add-user" element={<AddUser />} />
            <Route path="/security/edit-user/:id" element={<EditUser />} />
            <Route path="/security/billing" element={<Billing />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ConfigProvider>
  );
}

//<Route path="*" element={authenticate ? <DashboardHome /> : <Login />} />

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);
