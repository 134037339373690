const config = {

    // base_url: "http://localhost:52476/api/",
    // MEDIA_ROOT_URL: "http://localhost:8000/",
   
    // base_url: "http://localhost/api/index.php/",
   
    // base_url: "https://api.petrol-pump.com/api/",
    base_url: "https://developerapi.petrol-pump.com/api/",
    images: "https://app.petrol-pump.com/images/",

    date_format:"DD MMM YYYY"
  
  
  };
  export default config;