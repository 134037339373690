import AdmoreReports from '../../assets/images/PumpLogos/ADMORE_REPORTS.png';
import AlliedReport from '../../assets/images/PumpLogos/ALLIED_REPORT.png';
import AskarReport from '../../assets/images/PumpLogos/ASKAR_REPORT.png';
import AttockReport from '../../assets/images/PumpLogos/Attock_REPORT.png';
import BakriReport from '../../assets/images/PumpLogos/BAKRI_REPORT.png';
import BeEnergyReport from '../../assets/images/PumpLogos/BeEnergy_REPORT.png';
import BycoReport from '../../assets/images/PumpLogos/BYCO_REPORT.png';
import CaltexReport from '../../assets/images/PumpLogos/Caltex_REPORT.png';
import EuroReport from '../../assets/images/PumpLogos/Euro_REPORT.png';
import FlowReport from '../../assets/images/PumpLogos/FLOW_REPORT.png';
import GoGreenReport from '../../assets/images/PumpLogos/GOGREEN_REPORT.png';
import HascolReport from '../../assets/images/PumpLogos/Hascol_REPORT.png';
import JinnReport from '../../assets/images/PumpLogos/Jinn_REPORT.png';
import MyPetroleumReport from '../../assets/images/PumpLogos/MyPetroleum_REPORT.png';
import OilcoReport from '../../assets/images/PumpLogos/OILCO_REPORT.png';
import OtoReport from '../../assets/images/PumpLogos/OTO_REPORT.png';
import PsoReport from '../../assets/images/PumpLogos/PSO_REPORT.png';
import ShellReport from '../../assets/images/PumpLogos/Shell_REPORT.png';
import TotalReport from '../../assets/images/PumpLogos/TOTAL_REPORT.png';
import VitalReport from '../../assets/images/PumpLogos/Vital_REPORT.png';
import ZoomReports from '../../assets/images/PumpLogos/Zoom_REPORTS.png';

export const Logos=[
    
        { src: AdmoreReports, title: "ADMORE_TITLE.PNG"},
        { src: AlliedReport, title: "ALLIED_TITLE.png"},
        { src: AskarReport, title: "ASKAR_TITLE.png"},
        { src:AttockReport, title: "Attock_TITLE.PNG"},
        { src: BakriReport, title: "BAKRI_TITLE.png"},
        { src: BeEnergyReport, title: "BeEnergy_TITLE.png"},
        { src: BycoReport, title: "BYCO_TITLE.png"},
        { src: CaltexReport, title: "Caltex_TITLE.png"},
        { src: EuroReport, title: "Euro_TITLE.png"},
        { src: FlowReport, title: "FLOW_TITLE.png"},
        { src: GoGreenReport, title: "GOGREEN_TITLE.png"},
        { src: HascolReport, title: "Hascol_TITLE.png"},
        { src: JinnReport , title: "Jinn_TITLE.PNG"},
        { src: MyPetroleumReport, title: "MyPetroleum_TITLE.png"},
        { src: OilcoReport, title: "OILCO_TITLE.png"},
        { src: OtoReport , title: "OTO_TITLE.png"},
        { src: PsoReport, title: "PSO_TITLE.PNG"},
        { src:ShellReport, title: "Shell_TITLE.png"},
        { src: TotalReport, title: "TOTAL_TITLE.png"},
        { src: VitalReport , title: "Vital_TITLE.png"},
        { src: ZoomReports , title: "Zoom_TITLE.PNG"},
]