import React from "react";
import { NavLink } from "react-router-dom";

function StatBox(props) {
  return (
    <div className="dash-card no-shadow">
      <div className="stat-box">
        <p>{props.label}</p>
        <h4>Rs. {props.value}</h4>
        <NavLink to={props.link}>{props.linktext}</NavLink>
      </div>
    </div>
  );
}

export default StatBox;
