import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import axios from "axios";
import config from "../../../config";
import PrintHeaderAccount from "../PrintHeaderAccount";
import { Spin } from "antd";

function PrintAccountList() {
  const urlParams = new URLSearchParams(window.location.search);
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const orderby = urlParams.get("OrderBy");
  const currentPage = urlParams.get("PageNo");
  const pageSize = urlParams.get("PageSize");
  const BalanceType = urlParams.get("BalanceType");
  const AccountTypeID = urlParams.get("Account_Type_ID");
  const Dated = urlParams.get("Dated");
  const searchedName = urlParams.get("Name");
  const [data, setData] = useState([]);

  const [listofAccounts, SetlistofAccounts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      OrderBy: orderby,
      PageNo: currentPage,
      PageSize: pageSize,
      BalanceType: BalanceType,
      Account_Type_ID: AccountTypeID,
      Dated: Dated,
      Name: searchedName,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccounts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofAccounts(response.data.listofAccounts);
          setData(response.data);
          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
      //flexGrow: 1
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    table: {
      display: "flex",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    th: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    thValue: {
      fontSize: "8pt",
      fontWeight: "bold",
    },

    row: {
      flexDirection: "row",
    },
    td: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    tdValue: {
      fontSize: "8pt",
    },
  });

  const generateData = () => {
    if (listofAccounts.length > 0) {
      return listofAccounts.map((item, index) => {
        return (
          <View key={index} style={styles.row}>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{index + 1}</Text>
            </View>

            <View style={[styles.td, { width: 150 }]}>
              <Text style={styles.tdValue}>{item.Name}</Text>
            </View>
            <View style={[styles.td, { width: 150 }]}>
              <Text style={styles.tdValue}>{item.Mobile_No}</Text>
            </View>
            <View style={[styles.td, { width: 150 }]}>
              <Text style={styles.tdValue}>{item.Email_Address}</Text>
            </View>
            <View style={[styles.td, { width: 100, textAlign: "right" }]}>
              <Text style={styles.tdValue}>{item.Balance}</Text>
            </View>
            <View style={[styles.td, { width: 80 }]}>
              <Text style={styles.tdValue}>{item.BalanceType}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <PrintHeaderAccount />
        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={{ fontSize: "12pt" }}>List of Accounts</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>Sr No.</Text>
              </View>
              <View style={[styles.th, { width: 150 }]}>
                <Text style={styles.thValue}>Name</Text>
              </View>
              <View style={[styles.th, { width: 150 }]}>
                <Text style={styles.thValue}>Mobile No</Text>
              </View>
              <View style={[styles.th, { width: 150 }]}>
                <Text style={styles.thValue}>Email</Text>
              </View>
              <View style={[styles.th, { width: 100, textAlign: "right" }]}>
                <Text style={styles.thValue}>Current Balance</Text>
              </View>
              <View style={[styles.th, { width: 80 }]}>
                <Text style={styles.thValue}>Type</Text>
              </View>
            </View>
            {generateData()}
            <View style={[styles.tableHead, { fontSize: "10pt" }]}>
              <View style={[styles.th, { width: 430, textAlign: "right" }]}>
                <Text style={styles.thValue}>Total</Text>
              </View>
              <View style={[styles.th, { width: 85, textAlign: "left" }]}>
                <Text style={styles.thValue}>{data.DebitSum}</Text>
              </View>
              <View style={[styles.th, { width: 70, textAlign: "left" }]}>
                <Text style={styles.thValue}>Debit</Text>
              </View>
            </View>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 430, textAlign: "right" }]}>
                <Text style={styles.thValue}>Total</Text>
              </View>
              <View style={[styles.th, { width: 85, textAlign: "left" }]}>
                <Text style={styles.thValue}>{data.CreditSum}</Text>
              </View>
              <View style={[styles.th, { width: 70, textAlign: "left" }]}>
                <Text style={styles.thValue}>Credit</Text>
              </View>
            </View>
          </View>
          <View
            style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="accounts.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintAccountList;
