import React, { useEffect, useState } from "react";
import config from "../../config";
import axios from "axios";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Radio,
  message,
  Checkbox,
  Button,
  Space,
  Spin,
} from "antd";
import { NavLink,useNavigate } from "react-router-dom";
import logo from "../../assets/images/header-img.png";
import LoginChecked from "../Shared/LoginChecked";

function ChangeCompanyInfo() {

  const navigate = useNavigate();
  const [Profile, setProfile] = useState({});
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const basePath = "https://app.petrol-pump.com/Images/";
  const [FuelProviderID, setFuelProviderID] = useState(0);

  const [IsUpdated, setIsUpdated] = useState(false);
  const [ShowPumps, setShowPumps] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    document.title = "Change Company Information";
    setIsUpdated(false);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Members/GetFuelProviders",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setImageList(response.data.ListofFuelProviders);

        } else {
          // Handle error
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [IsUpdated]); // Empty dependency array ensures the effect runs only once on mount

  const handleRadioChange = (ID) => {
    setFuelProviderID(ID);
  };

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Members/GetCompanyProfile",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setProfile(response.data);
          setFuelProviderID(response.data.FuelProviderID);
          setShowPumps(response.data.Show_Pumps);
          form.setFieldsValue(response.data);
          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [IsUpdated]);

  const handleSubmit = (formData) => {
    setLoading(true);

    formData["Website"] =
      formData["Website"] == undefined ? "" : formData["Website"];
    formData["Phone"] = formData["Phone"] == undefined ? "" : formData["Phone"];

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      FuelProviderID: FuelProviderID,
      ...formData,
    };
    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Members/UpdateCompanyProfile",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };


    axios(api_config)
      .then(function (response) {
        console.log(response.data,'---------');
        if (response.data.status_code == 1) {
          setIsUpdated(true);
          message.success(response.data.status_message);
          setLoading(false);

          navigate("/dashboard/analytics");

          // Handle success
          window.location.reload();
        } else {
          // Handle error
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  let checkboxStates = [false, false];

  const handleCheckboxChange = (index) => {
    setShowPumps(index.target.checked);
  };

  return (
    <>

      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">Change Company Information</h6>
          </div>
         
              <Row gutter={[24, 24]}>
            <Col md={12} xs={24}>
              <Card title="Change Company Info" loading={loading}>
                <Form
                form={form}
                  name="basic"
                  layout="horizontal"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                 // initialValues={Profile}
                  onFinish={handleSubmit}
                  //    onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Pump Name"
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Pump name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Pump Name" />
                  </Form.Item>
                  <Form.Item
                    label="Address"
                    name="Address"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Address",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Your Address" />
                  </Form.Item>
                  <Form.Item label="Phone" name="Phone">
                    <Input placeholder="Enter Phone Number" />
                  </Form.Item>
                  <Form.Item label="Fax" name="Fax">
                    <Input placeholder="Enter fax machine" />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Email",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Email Address" disabled />
                  </Form.Item>
                  <Form.Item
                    label="Mobile"
                    name="Mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Mobile No",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Mobile Number" disabled />
                  </Form.Item>
                  <Form.Item label="Website" name="Website">
                    <Input placeholder="Ex. www.petrol-pump.com" />
                  </Form.Item>
                  <Form.Item label="Show Pumps" name="Show_Pumps">
                    <Checkbox
                      onChange={handleCheckboxChange}
                      checked={ShowPumps}
                    >
                      Show Nozzle Machines
                    </Checkbox>
                  </Form.Item>
                  <Form.Item>
                  <div className="sale-footer">
                <div className="text-right" style={{ marginTop: "30px" }}>
                    <Space wrap>
                      <Button
                        type="primary"
                        size="large"
                        shape="round"
                        htmlType="submit"
                      >
                        Update Information
                      </Button>
                    </Space>
                  
                </div>
              </div>
                  </Form.Item>
                </Form>
              </Card>
            </Col>

            <Col md={12} xs={24}>
              <Card title="Change Company Logo" loading={loading}>
                <Row gutter={[16, 16]}>
                  {imageList.map((item, index) => (
                    <Col key={index} md={6} xs={8}>
                      <Radio.Group
                        onChange={() => handleRadioChange(item.ID)}
                        value={FuelProviderID}
                      >
                        <Radio value={item.ID}>
                          <img
                            src={`${basePath}${item.Logo_Reports}`}
                            alt={`Image ${index}`}
                            style={{ width: "100%" }}
                          />
                        </Radio>
                      </Radio.Group>
                    </Col>
                  ))}
                </Row>
              </Card>
            </Col>
          </Row>
           
          
        </div>
      </div>
    </>
  );
}

export default ChangeCompanyInfo;
