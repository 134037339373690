import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from "../../../config";
import Header from "../../Accounts/PrintHeaderAccount";
import { Spin } from "antd";

const PrintVouchersDetail = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const voucher_id = urlParams.get("voucher_id");
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(0);

  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");
  const AccountID = urlParams.get("AccountID");
  const VoucherType = urlParams.get("VoucherType");
  const DebitAccountID = urlParams.get("DebitAccountID");
  const creditAccountID = urlParams.get("creditAccountID");

  const [listofVouchers, SetlistofVouchers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate:StartDate,
      EndDate:EndDate,
      AccountID: AccountID,
      VoucherType: VoucherType,
      Debit_Account_ID: DebitAccountID,
      Credit_Account_ID: creditAccountID,
      pageNo:pageNo,
      pageSize:2000,
    };
    console.log("🚀 ~ useEffect ~ data:", data);
    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/GetVouchers",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log("response", response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofVouchers(response.data.listofVouchers);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  console.log("List Of Vouchers",listofVouchers)

  const styles = StyleSheet.create({
    page: {
      //flexDirection: 'row',
      backgroundColor: "#FFF",
    },
    section: {
      margin: 10,
      padding: 10,
      flexDirection: "column",
      marginBottom: 20,
      //flexGrow: 1
    },
    header: {
      margin: 10,
      padding: 10,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottomWidth: 0.5,
    },
    title: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    table: {
      display: "flex",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      marginBottom: 10,
    },
    tableHead: {
      flexDirection: "row",
      backgroundColor: "rgba(0,0,0,0.05)",
    },
    th: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    thValue: {
      fontSize: "8pt",
      fontWeight: "bold",
    },

    row: {
      flexDirection: "row",
    },
    td: {
      padding: 5,
      borderStyle: "solid",
      borderWidth: 0.1,
      borderColor: "#000",
    },
    tdValue: {
      fontSize: "8pt",
      color: "black",
    },
  });

  console.log(listofVouchers);

  const generateData = () => {
    if (listofVouchers.length > 0) {
      return listofVouchers.map((item, index) => {
        return (
          <View key={index} style={styles.row}>
            <View style={[styles.td, { width: 30 }]}>
              <Text style={styles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[styles.td, { width: 80 }]}>
              <Text style={styles.tdValue}>{moment(item.Dated).format("DD MMM YYYY")}</Text>
            </View>
            <View style={[styles.td, { width: 50 }]}>
              <Text style={styles.tdValue}>{item.VoucherNo}</Text>
            </View>

            <View style={[styles.td, { width: 100 }]}>
              <Text style={styles.tdValue}>{item.Debit_Account?.Name}</Text>
            </View>
            <View style={[styles.td, { width: 100 }]}>
              <Text style={styles.tdValue}>{item.Credit_Account?.Name}</Text>
            </View>
            <View style={[styles.td, { width: 120, textAlign: "right" }]}>
              <Text style={styles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[styles.td, { width: 100 }]}>
              <Text style={[styles.tdValue,{textAlign:"right"}]}>{item.Amount}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header />
        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={{ fontSize: "12pt" }}>Voucher Details</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 30 }]}>
                <Text style={styles.thValue}>SR.</Text>
              </View>
              <View style={[styles.td, { width: 80 }]}>
                <Text style={styles.thValue}>Dated</Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}>No</Text>
              </View>

              <View style={[styles.th, { width: 100 }]}>
                <Text style={styles.thValue}>Debit Account</Text>
              </View>
              <View style={[styles.th, { width: 100 }]}>
                <Text style={styles.thValue}>Credit Account</Text>
              </View>
              <View style={[styles.th, { width: 120 }]}>
                <Text style={styles.thValue}>
                Description
                </Text>
              </View>
              <View style={[styles.th, { width: 100 }]}>
                <Text style={[styles.thValue,{textAlign:"right"}]}>Amount</Text>
              </View>
            </View>

            {generateData()}

            {/* <View style={styles.tableHead}>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}></Text>
              </View>
              <View style={[styles.th, { width: 50 }]}>
                <Text style={styles.thValue}></Text>
              </View>
              <View style={[styles.th, { width: 150 }]}>
                <Text style={styles.thValue}></Text>
              </View>

              <View style={[styles.th, { width: 100 }]}>
                <Text style={styles.thValue}></Text>
              </View>
              <View style={[styles.th, { width: 100 }]}>
                <Text
                  style={[
                    styles.thValue,
                    { textAlign: "right", fontWeight: "bold" },
                  ]}
                >
                  Total
                </Text>
              </View>
              <View style={[styles.th, { width: 120 }]}>
                <Text
                  style={[
                    styles.thValue,
                    { textAlign: "right", fontWeight: "bold" },
                  ]}
                >
                  {Math.round(calculateColumnTotal(listPayable, "Balance"))}
                </Text>
              </View>
              <View style={[styles.th, { width: 80 }]}>
                <Text style={styles.thValue}></Text>
              </View>
            </View> */}
          </View>
          <View
            style={{ marginBottom: 20, marginTop: 20, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
};

export default PrintVouchersDetail;
