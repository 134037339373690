export function calculateColumnTotal(data, columnName) {
  let total = 0;

  data.forEach((row) => {
    const columnValue = parseFloat(row[columnName]);
    total += isNaN(columnValue) ? 0 : columnValue;
  });

  return total;
}

// export const RenderHTMLString = (htmlString) => {
//   // Your function logic goes here
//   return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
// };


export const RenderHTMLStringWithoutDiv = (htmlString) => {
  return (
   
      <table className="add-render-table">
      <tbody dangerouslySetInnerHTML={{ __html: htmlString }} />
    </table>
    
    
  );
};