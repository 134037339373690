import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import config from "../../config";
import dayjs from "dayjs";
import moment from "moment";
import {
  Table,
  Button,
  DatePicker,
  Form,
  Select,
  Row,
  Col,
  Tooltip,
} from "antd";
import { NavLink } from "react-router-dom";
import { PrinterOutlined, EditOutlined } from "@ant-design/icons";
import LoginChecked from "../Shared/LoginChecked";
function ExpensesReport() {
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [EndDate, SetEndDate] = useState("2024-05-01");
  const [StartDate, SetStartDate] = useState("2024-05-31");
  const [ReportType, setReportType] = useState("Account Wise");
  const [ExpenseData, setExpenseData] = useState([]);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [DatewiseList, setDateWiseList] = useState([]);

  useEffect(() => {
    document.title = "Expenses Report";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      ReportType: ReportType,
    };
    console.log("this is data", data);
    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetExpenseReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setExpenseData(response.data);
          setListOfRecords(response.data.ListofVouchers);
          setDateWiseList(response.data.ListofExpenseVouchers);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }, [EndDate, StartDate, ReportType]);

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
    },
    {
      title: "Dated",
      dataIndex: "Created_Date",
      key: "Dated",
      render: (text, record) => {
        const formattedDate = moment(record.Created_Date).format(config.date_format);
        return <span>{formattedDate}</span>;
      }
    },
    {
      title: "Debit Account	",
      dataIndex: "Debit_Account	",
      key: "DebitAccount	",
      render: (_, record) => record.Debit_Account?.Name,
    },
    {
      title: "	Credit Account	",
      dataIndex: "	Credit_Account",
      key: "	CreditAccount	",
      render: (_, record) => record.Credit_Account?.Name,
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      align: "right",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      align: "default",
      render: (_, record) => (
        <Tooltip title="Edit Account">
          <NavLink to={`/vouchers/add-voucher/?ID=${record.ID}`}>
            <EditOutlined />
          </NavLink>
        </Tooltip>
      ),
    },
  ];
  const columns1 = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "ID",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "Dated",
      render: (_, record) => dayjs(record.Dated).format("YYYY-MM-DD"),
    },
    {
      title: "Amount	",
      dataIndex: "Amount",
      key: "Amount	",
      align: "right",
    },
  ];

  const getFooterContentDate = () => (
    <Row gutter={[16, 16]}>
      {/* First Column */}
      <Col span={24}>
        <Row>
          <Col span={16}>
            <p>
              <b>Total Expense:</b>
            </p>
          </Col>
          <Col span={8} align="right">
            <p>
              {" "}
              <b>{ExpenseData.TotalExpenses}</b>
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
  const getFooterContent = () => (
    <Row gutter={[16, 16]}>
      {/* First Column */}
      <Col span={24}>
        <Row>
          <Col span={16}>
            <p>
              <b>Total Expense:</b>
            </p>
          </Col>
          <Col span={6} align="right">
            <p>
              {" "}
              <b>{ExpenseData.TotalExpenses}</b>
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
  const onDateChange = (date, value) => {
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const disableDate=(current)=>{
    return current && current >moment().endOf('day');
  }
  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Expenses Report</h3>

            <div className="header-actions">
              <NavLink
                to={`/report-printexpensreport/print-list/?StartDate=${StartDate}&EndDate=${EndDate}&ReportType=${ReportType}`}
                target="_blank"
              >
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  // style={{ marginBottom: 10 }}
                >
                  <PrinterOutlined />
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <RangePicker onChange={onDateChange}
                disabledDate={disableDate}/>
              </Form.Item>
              <Form.Item>
                <Select
                  allowClear
                  style={{
                    width: "160px",
                  }}
                  placeholder="Report Type"
                  onChange={(value) => setReportType(value)}
                  options={[
                    {
                      value: "Account Wise",
                      label: "Account Wise",
                    },
                    {
                      value: "Date Wise",
                      label: "Date Wise",
                    },
                  ]}
                />
              </Form.Item>
            </Form>
          </div>
          {loading || ReportType === "Account Wise" ? (
            <>
              <Table
                size="small"
                scroll={{
                  x: "100%",
                }}
                loading={loading}
                columns={columns}
                dataSource={ListOfRecords}
                // onChange={handleTableChange}
                footer={getFooterContent}
                pagination={false}
                bordered
              />{" "}
            </>
          ) : ReportType === "Date Wise" ? (
            <>
              <Table
                size="small"
                scroll={{
                  x: "100%",
                }}
                loading={loading}
                columns={columns1}
                dataSource={DatewiseList}
                // onChange={handleTableChange}
                footer={getFooterContentDate}
                pagination={false}
                bordered
              />
            </>
          ) : (
            <>
              <Table
                size="small"
                loading={loading}
                scroll={{
                  x: "100%",
                }}
                columns={columns}
                // dataSource={ListOfRecords}
                // onChange={handleTableChange}
                pagination={false}
                bordered
              />

              <Table
                size="small"
                scroll={{
                  x: "100%",
                }}
                loading={loading}
                columns={columns1}
                // dataSource={DatewiseList}
                // onChange={handleTableChange}
                pagination={false}
                bordered
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default ExpensesReport;
