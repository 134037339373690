import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import logo from "../../assets/images/Logo/Logo.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, message, Form, Input, Space } from "antd";
import {
  BankOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import GoogleLogin from "react-google-login";
function SignUp() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [SignUpSuccess, setSignUpSuccess] = useState(false);
  const [SignUpData, setSignUpData] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  let navigate = useNavigate();

  const clientId =
    "607267377656-dtdl0o89k1sdqbdj01tl6qq3lr645j2k.apps.googleusercontent.com";
  const responseGoogle = (response) => {
    if (response.error === "popup_closed_by_user") {
      console.warn("User closed the popup before completing the sign-in.");
    } else {
      console.log(response, "response");
      // Handle successful login
    }
  };

  useEffect(() => {
    setSignUpSuccess(false);
    document.title = "Sign Up";
    window.close();
  }, []);

  const handleSubmit = (formData) => {
    setLoading(true);

    const data = {
      ...formData,
    };
    console.log(data,"-----------------")

    var api_config = {
      method: "post",
      url: config.base_url + "Members/SignUpWithOTP",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setLoading(false);
          setSignUpSuccess(true);
          setSignUpData(response.data);
          //navigate('/login');
          // Handle success
        } else {
          setLoading(false);
          setSignUpSuccess(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleButtonClick = () => {
    const email = form.getFieldValue("Email");
    setIsButtonDisabled(true);
    setTimeout(() => setIsButtonDisabled(false), 60000);
    const data = {
        Email_Address: email,
    };

    console.log("Email", data);

    var api_config = {
        method: "post",
        url: config.base_url + "OTP/SendSignUpOTP",
        headers: {
            "Content-Type": "application/json",
        },
        data: data,
    };

    axios(api_config)
        .then(function(response) {
          if(response.data.status_code===1){
            console.log("response email", response.data);
            message.success('OTP sent successfully!');
            setIsButtonDisabled(false);
            
          }
        })
        .catch((error) => {
            message.error('Error sending OTP: ' + error.message);
        });
};

  return (
    <>
      <div className="auth-wrap">
        <div>
          <div className="auth-wrap">
            <div className="auth-head">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              <p>Welcome</p>
              <h1>Create your account</h1>
            </div>
            <div className="auth-card" style={{paddingBottom:"20px"}}>
              {SignUpSuccess ? (
                <>
                  <h4 className="text-center">Welcome to Your New Account!</h4>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "rgba(0,0,0,0.6)",
                      fontWeight: "normal",
                    }}
                  >
                    Thank you for signing up! Below are your account details.
                    Please take a moment to review and remember your pump code
                    and email for future access. <br />
                    <br />
                    Important: Keep your business code and email safe. You'll
                    need these to manage your account and access our services.
                  </p>

                  <ul className="signup-list">
                    <li>
                      <span className="lbl">Pump Code</span>
                      <span className="val">{SignUpData.PumpCode}</span>
                    </li>
                    <li>
                      <span className="lbl">Email</span>
                      <span className="val">{SignUpData.EmailAddress}</span>
                    </li>
                  </ul>

                  <Link to="/login">
                    <Button size="large" type="primary" block>
                      Login Now
                    </Button>
                  </Link>
                </>
              ) : (
                <>
                  <Form
                    form={form}
                    name="auth-form"
                    className="auth-form"
                    size="large"
                    layout="vertical"
                    onFinish={handleSubmit}
                  >
                    <Form.Item
                      name="Name"
                      label="Petrol Pump Name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Petrol Pump Name!",
                        },
                      ]}
                    >
                      <Input
                        prefix={<BankOutlined />}
                        placeholder="Petrol Pump Name"
                      />
                    </Form.Item>

                    <Form.Item
                      name="Mobile"
                      label="Mobile No"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Mobile No",
                        },
                      ]}
                    >
                      <Input
                        prefix={<PhoneOutlined />}
                        placeholder="Mobile No"
                      />
                    </Form.Item>

                    <Form.Item
                      name="Email"
                      label="Email Address"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your Email Address!",
                        },
                      ]}
                    >
                      <Space.Compact style={{ width: "100%" }}>
                        <Input
                          prefix={<MailOutlined />}
                          type="email"
                          placeholder="Email Address"
                        />
                        <Button
                          type="primary"
                          onClick={() => handleButtonClick()}
                          disabled={isButtonDisabled}
                        >
                          Get Code
                        </Button>
                      </Space.Compact>
                    </Form.Item>
                    <Form.Item
                      name="OTPCode"
                      label="Verification Code"
                      rules={[
                        {
                          required: true,
                          message: "Please enter verification code!",
                        },
                      ]}
                    >
                      <Input
                        prefix={<MailOutlined />}
                        name="OTPCode"
                        type="verification"
                        placeholder="Verification Code"
                        rules={[
                          {
                            required: true,
                            message: "Please input your Verification Code!",
                          },
                        ]}

                      />
                    </Form.Item>

                    <Form.Item
                      name="Password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Password!",
                        },
                      ]}
                    >
                      <Input.Password
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="Password"
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        size="large"
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        block
                      >
                        Sign Up
                      </Button>
                    </Form.Item>
                  </Form>
                  {/* <div id="signInButton">
                      <GoogleLogin
                        clientId={clientId}
                        buttonText="Login with Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                      />
                    </div> */}

                  <div className="text-center" style={{ marginTop: "20px" }}>
                    Already have an account? <br />
                    <Link to="/login">Login Now</Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
