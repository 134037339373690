import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Dropdown, message } from 'antd';
import config from '../../config';

const Notifications = () => {
    const [accessKey, setAccessKey] = useState(localStorage.getItem("AccessKey"));
    const [userID, setUserID] = useState(localStorage.getItem("ID"));
    const [isUpdated, setIsUpdated] = useState(false);
    const [selectedBtnId, setSelectedBtnId] = useState(-1);
    const [listOfNotifications, setListOfNotifications] = useState([]);
    const [unseenCount, setUnseenCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingReadAll, setLoadingReadAll] = useState(false);
    const [loadingDeleteAll, setLoadingDeleteAll] = useState(false);
    const [isChanged, setIsChanged] = useState(0);

    useEffect(() => {
        setIsUpdated(false);
        const params = {
            AccessKey: accessKey,
            UserID: userID,
        };

        const apiConfig = {
            method: 'post',
            url: `${config.base_url}Notification/GetAllNotifications`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: params
        };

        axios(apiConfig)
            .then(response => {
                if (response.data.status_code === 1) {
                    setListOfNotifications(response.data.ListofNotifcations);
                    setUnseenCount(response.data.ListofNotifcations.filter(item => !item.Is_Seen).length);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, [isUpdated]);

    const renderNotifications = () => {
        if (listOfNotifications.length > 0) {
            return listOfNotifications.map((item, index) => (
                <li className={`${item.Is_Seen ? '' : 'active'}`} key={index} onClick={() => markRead(item.ID)}>
                    <div className={`indicator ${item.Is_Seen ? '' : 'active'}`}></div>
                    <div className="message-content">
                        <h5>{item.Title}</h5>
                        <p>{item.Detail}</p>
                        <span>{moment(item.Created_Date).format("DD MMMM YYYY")}</span>
                    </div>
                    <svg onClick={(e) => { e.stopPropagation(); markDelete(item.ID); }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </li>
            ));
        }
    };

    const handleDeleteAll = () => {
        setLoadingDeleteAll(true);
        const params = {
            AccessKey: accessKey,
            UserID: userID,
        };

        const apiConfig = {
            method: 'post',
            url: `${config.base_url}Notification/DeleteAllNotifications`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: params
        };

        axios(apiConfig)
            .then(response => {
                if (response.data.status_code === 1) {
                    setIsUpdated(true);
                    message.success(response.data.status_message);
                }
            })
            .finally(() => {
                setLoadingDeleteAll(false);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const handleReadAll = () => {
        setLoadingReadAll(true);
        const params = {
            AccessKey: accessKey,
            UserID: userID,
        };

        const apiConfig = {
            method: 'post',
            url: `${config.base_url}Notification/ReadAllNotifications`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: params
        };

        axios(apiConfig)
            .then(response => {
                if (response.data.status_code === 1) {
                    setIsUpdated(true);
                    message.success(response.data.status_message);
                }
            })
            .finally(() => {
                setLoadingReadAll(false);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const markDelete = (notificationID) => {
        setIsChanged(isChanged + 1);
        setLoading(true);
        setSelectedBtnId(notificationID);
        const params = {
            AccessKey: accessKey,
            UserID: userID,
            ID: notificationID
        };

        const apiConfig = {
            method: 'post',
            url: `${config.base_url}Notification/DeleteNotification`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: params
        };

        axios(apiConfig)
            .then(response => {
                if (response.data.status_code === 1) {
                    setIsUpdated(true);
                }
            })
            .finally(() => {
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const markRead = (notificationID) => {
        setIsChanged(isChanged + 1);
        setLoading(true);
        setSelectedBtnId(notificationID);
        const params = {
            AccessKey: accessKey,
            UserID: userID,
            ID: notificationID
        };

        const apiConfig = {
            method: 'post',
            url: `${config.base_url}Notification/ReadNotification`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: params
        };

        axios(apiConfig)
            .then(response => {
                if (response.data.status_code === 1) {
                    setIsUpdated(true);
                }
            })
            .finally(() => {
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const notificationContent = (
        <div className="notifications">
            <div className="notifications-wrap">
                <div className="notifications-content">
                    <div className="notifications-head">
                        <label>Notifications</label>
                        <ul>
                            <li>
                                {loadingReadAll ? '...' : (
                                    <div onClick={handleReadAll}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                        </svg>
                                        <span>Read All</span>
                                    </div>
                                )}
                            </li>
                            <li>
                                {loadingDeleteAll ? '...' : (
                                    <div onClick={handleDeleteAll}>
                                        <span>Clear All</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                    <ul className='notification-list scrollbar'>
                        {renderNotifications()}
                    </ul>
                </div>
            </div>
        </div>
    );

    return (
        <Dropdown overlay={notificationContent} trigger={['click']}>
            <a className='' onClick={(e) => e.preventDefault()}>
                <div>
                    <span className={`notifications-icon ${unseenCount === 0 ? '' : 'active'}`}></span>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6 bell-icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                    </svg>
                </div>
            </a>
        </Dropdown>
    );
};

export default Notifications;
